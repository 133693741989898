import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import { DialogActionsStyled, Title } from './ConfirmModal.styles';
import Button from '../Button';

interface ConfirmModalProps {
  open: boolean;
  title: string;
  onClose?: () => void;
  onAccept?: () => void;
}

const ConfirmModal = ({ open, onClose, onAccept, title }: ConfirmModalProps) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>
      <Title>{title}</Title>
    </DialogTitle>
    <DialogActionsStyled>
      <Button onClick={onAccept} variant="contained">Accept</Button>
      <Button onClick={onClose} variant="outlined">Cancel</Button>
    </DialogActionsStyled>
  </Dialog>
);

export default ConfirmModal;
