import { styled } from '@mui/system';

interface ViewSudraniaLinkProps {
  disabled?: boolean;
}

export const DealsStyled = styled('div')`
  padding: 30px 32px;
`;

export const DealsLoader = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;

export const ViewSudraniaLink = styled('a')<ViewSudraniaLinkProps>`
  text-decoration: none;
  pointer-events: ${(props) => props.disabled ? 'none' : 'initial'};
`;
