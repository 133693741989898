// Hooks
import { useAppSelector } from 'hooks';
import { useMemo } from 'react';

// Store
import { selectSelectedUser, selectUserPortfolio } from 'store/users/selectors';

// Utils
import { formatToCurrencyString } from 'utils/helpers';

// Components and Styles
import PortfolioInfo from './PortfolioInfo';
import PortfolioTable from './PortfolioTable';
import { PortfolioTableWrapper } from '../Portfolio.styles';

export interface PortfolioFund {
  id: string;
  name: string;
  invested: number;
  payouts: number;
}

const PortfolioOverview = () => {
  const selectedUser = useAppSelector(selectSelectedUser);
  const portfolio = useAppSelector(selectUserPortfolio);

  const funds = useMemo((): PortfolioFund[] => {
    let funds: PortfolioFund[] = [];
    if (!portfolio?.investmentDataList) {
      return funds;
    }
    for (let i = 0; i < portfolio.investmentDataList.length; i++) {
      const data = portfolio.investmentDataList[i];
      const isExist = funds.find((fund) => fund.id === data.fund_id);
      if (isExist) {
        funds = funds.map((fund) => {
          if (fund.id === data.fund_id) {
            return {
              ...fund,
              invested: fund.invested + parseInt(data.total_invested),
              payouts: fund.payouts + parseInt(data.withdrawal),
            }
          }
          return fund;
        })
      } else {
        funds.push({
          id: data.fund_id,
          invested: parseInt(data.total_invested),
          payouts: parseInt(data.withdrawal),
          name: data.fund_name,
        });
      }
    }
    return funds;
  }, [portfolio]);

  if (!selectedUser) return null;

  return (
    <div>
      <PortfolioInfo
        email={selectedUser.email}
        name={selectedUser.name}
        totalInvested={
          formatToCurrencyString(portfolio?.total_invested ? parseInt(portfolio?.total_invested) : 0)
        }
        totalPayouts={
          formatToCurrencyString(portfolio?.total_withdrawals ? parseInt(portfolio?.total_withdrawals) : 0)
        }
        totalProfit={
          formatToCurrencyString(portfolio?.total_profit ? parseInt(portfolio?.total_profit) : 0)
        }
      />
      <PortfolioTableWrapper>
        <PortfolioTable funds={funds} />
      </PortfolioTableWrapper>
    </div>
  )
}

export default PortfolioOverview;
