import Button from 'components/common/Button';
import { TableCreateEditModalFooterButtons, TableCreateEditModalFooterContainer } from './TableCreateEditModalFooter.styles';

interface TableCreateEditModalFooterProps {
  id?: string;
  loading?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

const TableCreateEditModalFooter = ({ id, loading, onSubmit, onCancel }: TableCreateEditModalFooterProps) => (
  <TableCreateEditModalFooterContainer>
    <TableCreateEditModalFooterButtons>
      <Button onClick={onSubmit} loading={loading}>OK</Button>
      <Button variant="outlined" onClick={onCancel}>
        Cancel
      </Button>
    </TableCreateEditModalFooterButtons>
    {id && <div>id: {id}</div>}
  </TableCreateEditModalFooterContainer>
)

export default TableCreateEditModalFooter;
