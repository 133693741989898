import { PropsWithChildren } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { ButtonStyled } from './Button.styles';

interface CommonButtonProps {
  disabled?: boolean;
  width?: string;
  type?: 'button' | 'submit';
  variant?: 'contained' | 'outlined';
  loading?: boolean;
  onClick?: () => void;
}

const CommonButton = ({
  children,
  disabled,
  width,
  onClick,
  loading = false,
  variant = 'contained',
  type = 'button',
  ...otherProps
}: PropsWithChildren<CommonButtonProps>) => (
  <ButtonStyled
    variant={variant}
    disabled={disabled || loading}
    width={width}
    type={type}
    onClick={onClick}
    {...otherProps}
  >
    {!loading && children}
    {loading && (
      <CircularProgress size={20} color="inherit" />
    )}
  </ButtonStyled>
);

export default CommonButton;
