import { styled } from '@mui/system';

export const TableRowHead = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const TableRowHeadLeft = styled('div')`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  color: #9B9B9B;
  font-size: 16px;
  svg {
    color: #D8D8D8;
  }
`;

export const TableRowHeadIndex = styled('div')`
  color: black;
  margin-left: 18px;
  margin-right: 12px;
`;

export const TableRowActions = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
  margin-top: 20px;
`;

export const TableRowHeadOption = styled('div')`
  color: black;
  margin-right: 20px;
`;

export const NormalRowContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 90px;
  column-gap: 30px;
`;

export const HeadingRowContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 90px;
  column-gap: 30px;
`;
