import { NameValueItem } from 'types/Tables';
import NameValueTable from '../NameValueTable';

interface DealsCreateModalTermsProps {
  terms: NameValueItem[];
  setTerms: (items: NameValueItem[]) => void;
}

const DealsCreateModalTerms = ({ terms, setTerms }: DealsCreateModalTermsProps) => {
  return (
    <div>
      <NameValueTable title="Terms Table" items={terms} setItems={setTerms} />
    </div>
  );
}

export default DealsCreateModalTerms;
