import { useCallback, useEffect, useState } from 'react';
import { omit } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { type TableRow, TableRowStyle } from 'types/Tables';

// Store
import { useAppDispatch, useAppSelector } from 'hooks'
import {
  selectActiveSection,
  selectShowCreateTermModal,
  selectShowFinancialTermModal,
  selectTablesLoading,
  selectTableToEdit,
} from 'store/tables/selectors';
import {
  addTable,
  setShowFinancialCreateModal,
  setShowTermCreateModal,
  setTableToEdit,
  updateTable,
} from 'store/tables/actions';

// Styles and Components
import { TableCreateEditModalBlock } from './TableCreateEditModal.styles';
import TableCreateEditModalHeader from './TableCreateEditModalHeader';
import TableCreateEditModalBody from './TableCreateEditModalBody';
import TableCreateEditModalFooter from './TableCreateEditModalFooter';

const ROW_DEFAULT = {
  id: uuidv4(),
  key: '',
  value: '',
  style: TableRowStyle.NORMAL,
};

const TableCreateEditModal = () => {
  const dispatch = useAppDispatch();

  const termOpen = useAppSelector(selectShowCreateTermModal);
  const financialOpen = useAppSelector(selectShowFinancialTermModal);
  const loading = useAppSelector(selectTablesLoading);
  const tableToEdit = useAppSelector(selectTableToEdit);
  const activeSection = useAppSelector(selectActiveSection);

  const [name, setName] = useState<string>('');
  const [rows, setRows] = useState<TableRow[]>([ROW_DEFAULT]);
  const [nameError, setNameError] = useState<string>('');
  const [rowsError, setRowsError] = useState<string>('');

  const addRow = useCallback(() => {
    setRows([...rows, {
      ...ROW_DEFAULT,
      id: uuidv4(),
    }]);
  }, [rows]);

  const deleteRow = useCallback((rowIndex: number) => {
    setRows(rows.filter((_, index) => index !== rowIndex));
  }, [rows]);

  const updateRow = useCallback((rowIndex: number, data: TableRow) => {
    setRows(rows.map((row, index) => {
      if (index === rowIndex) {
        return data;
      }
      return row;
    }));
  }, [rows]);

  const duplicateRow = useCallback((data: TableRow) => {
    setRows([...rows, omit(['id'], data)]);
  }, [rows]);

  const clearData = useCallback(() => {
    setName('');
    setRows([{
      ...ROW_DEFAULT,
      id: uuidv4(),
    }]);
  }, []);

  const reorder = useCallback((startIndex: number, endIndex: number) => {
    const startRow = rows[startIndex];
    const endRow = rows[endIndex];
    const newRows = rows.map((row, index) => {
      if (index === startIndex) {
        return endRow;
      } else if (index === endIndex) {
        return startRow;
      }
      return row;
    });
    setRows(newRows);
  }, [rows]);

  const isValid = useCallback(() => {
    let isValid = true;
    if (!name) {
      setNameError('Name is required');
      isValid = false;
    } else {
      setNameError('');
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        if (row.style === TableRowStyle.HEADING && !row.value) {
          isValid = false;
          setRowsError('Please fill in all fields of the added rows');
          break;
        }
        if (
          (row.style === TableRowStyle.NORMAL || row.style === TableRowStyle.HIGHLIGHT) &&
          (!row.key || !row.value)
        ) {
          isValid = false;
          setRowsError('Please fill in all fields of the added rows');
          break;
        }
      }
    }
    if (isValid) {
      setRowsError('');
    }
    return isValid;
  }, [name, rows]);

  const onClose = useCallback(() => {
    dispatch(setShowTermCreateModal(false));
    dispatch(setShowFinancialCreateModal(false));
    setTimeout(() => {
      dispatch(setTableToEdit(null));
      clearData();
    }, 50);
  }, [dispatch, clearData]);

  const submit = useCallback(() => {
    if (!isValid()) return;
    if (tableToEdit) {
      dispatch(updateTable({
        ...tableToEdit,
        name,
        table_rows: rows.map((row) => omit(['id'], row)),
      }));
    } else {
      dispatch(addTable({
        name,
        type: activeSection,
        table_rows: rows.map((row) => omit(['id'], row)),
      }));
    }
    clearData();
  }, [
    dispatch,
    clearData,
    isValid,
    name,
    rows,
    tableToEdit,
    activeSection,
  ]);

  useEffect(() => {
    if (tableToEdit) {
      setName(tableToEdit.name);
      setRows(tableToEdit.table_rows.map((row) => ({
        ...row,
        id: uuidv4(),
      })));
    }
  }, [dispatch, tableToEdit]);


  return (
    <TableCreateEditModalBlock
      open={termOpen || financialOpen}
      onClose={onClose}
    >
      <TableCreateEditModalHeader
        name={name}
        activeSection={activeSection}
        editMode={!!tableToEdit}
        error={nameError}
        onNameChange={setName}
      />
      <TableCreateEditModalBody
        {...{
          rows,
          error: rowsError,
          addRow,
          deleteRow,
          updateRow,
          duplicateRow,
          reorder,
        }}
      />
      <TableCreateEditModalFooter
        id={tableToEdit?.id}
        loading={loading}
        onCancel={onClose}
        onSubmit={submit}
      />
    </TableCreateEditModalBlock>
  )
}

export default TableCreateEditModal;
