import { SyntheticEvent, useEffect, useMemo } from 'react';
import { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';

// Components and Styles
import {
  DealsCreateModalStyled,
  DealsCreateModalBody,
  TabStyled,
  DealsCreateModalBodyContent,
  DealsCreateModalFooter,
  DealsCreateModalFooterButton,
} from './DealsCreateModal.styles';
import { Tabs } from 'components/common/Tabs';
import Button from 'components/common/Button';
import DealsCreateModalGeneral from './DealsCreateModalGeneral';
import DealsCreateModalDescriptions from './DealsCreateModalDescriptions';
import DealsCreateModalImages from './DealsCreateModalImages';
import DealsCreateModalFinancials from './DealsCreateModalFinancials';
import DealsCreateModalTerms from './DealsCreateModalTerms';

import DealsCreateModalHeader from './DealsCreateModalHeader';

// Store
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  createDeal,
  editDeal,
  setDealToEdit,
  setShowDealsCreateModal,
} from 'store/deals/actions';
import {
  selectDealsCreateLoading,
  selectDealToEdit,
  selectShowDealsCreateModal,
} from 'store/deals/selectors';
import { getFundDetails } from 'store/sudrania/actions';
import { selectFundDetailsError, selectFundDetailsLoading } from 'store/sudrania/selectors';
import { anyPass, isEmpty, isNil, reject } from 'ramda';
import { NameValueItem } from 'types/Tables';

export interface DealsCreateFields {
  sudrania_id: string;
  name: string;
  hours: number;
  minutes: number;
  date_opened: string;
  date_closed: string;
  short_description: string;
  featured_description: string;
  summary: string;
  minimum_interval: number;
}

const DealsCreateModal = () => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
  } = useForm<DealsCreateFields>();
  const dispatch = useAppDispatch();

  const showDealsCreateModal = useAppSelector(selectShowDealsCreateModal);
  const dealsCreateLoading = useAppSelector(selectDealsCreateLoading);
  const dealToEdit = useAppSelector(selectDealToEdit);
  const fundDetailsError = useAppSelector(selectFundDetailsError);
  const fundDetailsLoading = useAppSelector(selectFundDetailsLoading);

  const [tabValue, setTabValue] = useState<number>(0);
  const [hidden, setHidden] = useState<boolean>(false);
  const [featured, setFeatured] = useState<boolean>(false);
  const [featuredImage, setFeaturedImage] = useState<string>('');
  const [financials, setFinancials] = useState<NameValueItem[]>([]);
  const [terms, setTerms] = useState<NameValueItem[]>([]);
  const [pieChart, setPieChart] = useState<NameValueItem[]>([]);
  const [headerTable, setHeaderTable] = useState<NameValueItem[]>([]);

  const title = watch('name');
  const sudrania_id = watch('sudrania_id');
  const minimum_interval = watch('minimum_interval');

  const disabled = useMemo(() => {
    return !!fundDetailsError ||
      dealsCreateLoading ||
      fundDetailsLoading ||
      !title ||
      !minimum_interval;
  }, [
    fundDetailsError,
    dealsCreateLoading,
    fundDetailsLoading,
    title,
    minimum_interval,
  ]);

  const changeTabValue = useCallback(
    (_: SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
    },
    [],
  );

  const submit = useCallback((data: DealsCreateFields) => {
    const params = {
      ...data,
      hidden,
      featured,
      featured_image: featuredImage,
      financials,
      pie_chart: pieChart,
      terms,
      header_table: headerTable,
    };
    if(dealToEdit) {
      dispatch(editDeal({
        id: dealToEdit.id,
        data: reject(anyPass([isEmpty, isNil]))(params),
      }));
    } else {
      dispatch(createDeal(params));
    }
  }, [
    dispatch,
    dealToEdit,
    hidden,
    featured,
    featuredImage,
    financials,
    terms,
    headerTable,
    pieChart,
  ]);

  const onClose = useCallback(() => {
    dispatch(setShowDealsCreateModal(false));
  }, [dispatch]);

  useEffect(() => {
    if (!showDealsCreateModal) {
      reset();
      dispatch(setDealToEdit(null));
    }
  }, [dispatch, reset, showDealsCreateModal]);
  useEffect(() => {
    if (dealToEdit) {
      setValue('name', dealToEdit.name)
      setValue('sudrania_id', dealToEdit.sudrania_id);
      setValue('short_description', dealToEdit.short_description);
      setValue('featured_description', dealToEdit.featured_description);
      setValue('summary', dealToEdit.summary);
      setValue('minimum_interval', dealToEdit.minimum_interval);
      setFeaturedImage(dealToEdit.featured_image);
      setHidden(dealToEdit.hidden);
      setFeatured(dealToEdit.featured);
      setFinancials(dealToEdit.financials || []);
      setTerms(dealToEdit.terms || []);
      setPieChart(dealToEdit.pie_chart || []);
      setHeaderTable(dealToEdit.header_table || []);
    }
  }, [setValue, dealToEdit]);

  useEffect(() => {
    if (sudrania_id) {
      dispatch(getFundDetails(sudrania_id));
    }
  }, [dispatch, sudrania_id]);

  return (
    <DealsCreateModalStyled
      open={showDealsCreateModal}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <form onSubmit={handleSubmit(submit)}>
        <DealsCreateModalHeader
          {...{
            hidden,
            featured,
            setHidden,
            setFeatured,
          }}
        />
        <DealsCreateModalBody>
          <Tabs
            value={tabValue}
            onChange={changeTabValue}
          >
            <TabStyled disabled={disabled} label="General" />
            <TabStyled disabled={disabled} label="Descriptions" />
            <TabStyled disabled={disabled} label="Header" />
            <TabStyled disabled={disabled} label="Financials" />
            <TabStyled disabled={disabled} label="Terms" />
          </Tabs>
          <DealsCreateModalBodyContent smallPadding={tabValue === 2}>
            {tabValue === 0 && (
              <DealsCreateModalGeneral
                sudrania_id={sudrania_id}
                register={register}
              />
            )}
            {tabValue === 1 && <DealsCreateModalDescriptions register={register} />}
            {tabValue === 2 && (
              <DealsCreateModalImages
                featuredImage={featuredImage}
                headerTable={headerTable}
                setFeaturedImage={setFeaturedImage}
                setHeaderTable={setHeaderTable}
              />
            )}
            {tabValue === 3 && (
              <DealsCreateModalFinancials
                financials={financials}
                pieChart={pieChart}
                setFinancials={setFinancials}
                setPieChart={setPieChart}
              />
            )}
            {tabValue === 4 && (
              <DealsCreateModalTerms terms={terms} setTerms={setTerms} />
            )}
          </DealsCreateModalBodyContent>
        </DealsCreateModalBody>
        <DealsCreateModalFooter>
          <DealsCreateModalFooterButton>
            <Button
              type="submit"
              disabled={disabled}
              loading={dealsCreateLoading || fundDetailsLoading}
            >
              OK
            </Button>
            <Button variant="outlined" onClick={onClose}>Cancel</Button>
          </DealsCreateModalFooterButton>
          <div>
            {dealToEdit && `id: ${dealToEdit.id}`}
          </div>
        </DealsCreateModalFooter>
      </form>
    </DealsCreateModalStyled>
  );
}

export default DealsCreateModal;
