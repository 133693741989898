import type { PayloadAction } from '@reduxjs/toolkit';
import type { SudraniaFund, SudraniaFundDetails } from 'types/Sudrania';
import { setFundDetails, setFundDetailsError, setFundDetailsLoading, setFundsLoading, SudraniaError } from './actions';
import { createReducer } from '@reduxjs/toolkit';
import { setFunds, setError } from './actions';

export interface SudraniaState {
  error: SudraniaError;
  fundDetailsError: string;
  funds: SudraniaFund[];
  fundsLoading: boolean;
  fundDetails: SudraniaFundDetails | null;
  fundDetailsLoading: boolean;
}

const initialState: SudraniaState = {
  error: null,
  fundDetailsError: '',
  funds: [],
  fundsLoading: false,
  fundDetails: null,
  fundDetailsLoading: false,
};

const sudraniaReducer = createReducer(initialState, {
  [setError.type]: (state: SudraniaState, action: PayloadAction<SudraniaError>) => ({
    ...state,
    error: action.payload,
  }),
  [setFundDetailsError.type]: (state: SudraniaState, action: PayloadAction<string>) => ({
    ...state,
    fundDetailsError: action.payload,
  }),
  [setFunds.type]: (state: SudraniaState, action: PayloadAction<SudraniaFund[]>) => ({
    ...state,
    funds: action.payload,
  }),
  [setFundDetails.type]: (state: SudraniaState, action: PayloadAction<SudraniaFundDetails>) => ({
    ...state,
    fundDetails: action.payload,
  }),
  [setFundDetailsLoading.type]: (state: SudraniaState, action: PayloadAction<boolean>) => ({
    ...state,
    fundDetailsLoading: action.payload,
  }),
  [setFundsLoading.type]: (state: SudraniaState, action: PayloadAction<boolean>) => ({
    ...state,
    fundsLoading: action.payload,
  })
});

export default sudraniaReducer;
