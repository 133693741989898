import { all, fork } from 'redux-saga/effects';

import authSaga from './auth/saga';
import dealsSaga from './deals/saga';
import sudraniaSaga from './sudrania/saga';
import tablesSaga from './tables/saga';
import usersSaga from './users/saga';

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(usersSaga),
    fork(dealsSaga),
    fork(sudraniaSaga),
    fork(tablesSaga),
  ]);
}
