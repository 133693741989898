import { styled } from '@mui/system';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

export const PortfolioDialog = styled(Dialog)`
  padding: 0;
`;

export const PortfolioHeader = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding-left: 46px;
  padding-right: 26px;
  background-color: #1e1e1d;
  color: white;
  font-size: 21px;
  font-weight: 700;
`;

export const PortfolioHeaderClose = styled('div')`
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

export const PortfolioBody = styled(DialogContent)`
  padding: 0;
  min-height: 55vh;
`;

export const PortfolioLoading = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
`;

export const TabsWrapper = styled('div')`
  position: relative;
`;

export const TabsDivider = styled('div')`
  background-color: #dcdcdc;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const Placeholder = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 22px;
  font-weight: 500;
`;

export const PlaceholderText = styled('span')`
  opacity: 0.6;
`;

export const PortfolioTableWrapper = styled('div')`
  padding: 32px 46px;
`;
