import type { PayloadAction } from '@reduxjs/toolkit';
import type { Portfolio } from 'types/Portfolio';
import type { AccountMetadata, AccountNotifications, default as User } from 'types/User';
import {
  selectUser,
  setUserMetadata,
  setUserMetadataLoading,
  setUserNotifications,
  UsersError,
} from './actions';
import { createReducer } from '@reduxjs/toolkit';
import {
  setCreateUserLoading,
  setEditUserLoading,
  setShowCreateUserModal,
  setShowEditUserModal,
  setUsers,
  setTotalUsers,
  setUsersLoading,
  setError,
} from './actions';

export interface UsersState {
  users: User[];
  portfolio: Portfolio | null;
  userMetadata: AccountMetadata | null;
  userNotifications: AccountNotifications | null;
  selectedUser: User | null;
  usersLoading: boolean;
  totalUsers: number;
  error: UsersError;
  createUserLoading: boolean;
  editUserLoading: boolean;
  userMetadataLoading: boolean;
  portfolioLoading: boolean;
  showCreateUserModal: boolean;
  showEditUserModal: boolean;
}

const initialState: UsersState = {
  users: [],
  portfolio: null,
  userMetadata: null,
  userNotifications: null,
  selectedUser: null,
  usersLoading: false,
  totalUsers: 0,
  error: null,
  createUserLoading: false,
  editUserLoading: false,
  userMetadataLoading: false,
  portfolioLoading: false,
  showCreateUserModal: false,
  showEditUserModal: false,
};

const usersReducer = createReducer(initialState, {
  [setError.type]: (state: UsersState, action: PayloadAction<UsersError>) => ({
    ...state,
    error: action.payload,
  }),
  [setCreateUserLoading.type]: (state: UsersState, action: PayloadAction<boolean>) => ({
    ...state,
    createUserLoading: action.payload,
  }),
  [setEditUserLoading.type]: (state: UsersState, action: PayloadAction<boolean>) => ({
    ...state,
    editUserLoading: action.payload,
  }),
  [setShowCreateUserModal.type]: (state: UsersState, action: PayloadAction<boolean>) => ({
    ...state,
    showCreateUserModal: action.payload,
  }),
  [setShowEditUserModal.type]: (state: UsersState, action: PayloadAction<boolean>) => ({
    ...state,
    showEditUserModal: action.payload,
  }),
  [setUsers.type]: (state: UsersState, action: PayloadAction<User[]>) => ({
    ...state,
    users: action.payload,
  }),
  [setTotalUsers.type]: (state: UsersState, action: PayloadAction<number>) => ({
    ...state,
    totalUsers: action.payload,
  }),
  [selectUser.type]: (state: UsersState, action: PayloadAction<User>) => ({
    ...state,
    selectedUser: action.payload,
  }),
  [setUsersLoading.type]: (state: UsersState, action: PayloadAction<boolean>) => ({
    ...state,
    usersLoading: action.payload,
  }),
  [setUserMetadata.type]: (state: UsersState, action: PayloadAction<AccountMetadata>) => ({
    ...state,
    userMetadata: action.payload,
  }),
  [setUserMetadataLoading.type]: (state: UsersState, action: PayloadAction<boolean>) => ({
    ...state,
    userMetadataLoading: action.payload,
  }),
  [setUserNotifications.type]: (state: UsersState, action: PayloadAction<AccountNotifications>) => ({
    ...state,
    userNotifications: action.payload,
  }),
});

export default usersReducer;
