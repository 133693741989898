export interface NameValueItem {
  name: string;
  value: string;
}

export enum TableType {
  TERM = 'TERM',
  FINANCIAL = 'FINANCIAL',
}

export enum TableRowStyle {
  NORMAL = 'NORMAL',
  HEADING = 'HEADING',
  HIGHLIGHT = 'HIGHLIGHT',
}

export interface TableRow {
  id?: string;
  key: string;
  value: string;
  style: TableRowStyle;
}

export interface Table {
  id?: string;
  type: TableType;
  table_rows: TableRow[],
  name: string;
}

export interface GetTablesRequest {
  offset: number;
  total: number;
  name_contains: string;
  type?: string;
}
