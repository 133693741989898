import type User from 'types/User';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';

import { logout, setAppLoading, updateSessionSecret, updateUser } from './actions';

export interface AuthState {
  user: User | null;
  sessionSecret: string;
  appLoading: boolean;
}

const initialState: AuthState = {
  user: null,
  sessionSecret: '',
  appLoading: false,
};

const authReducer = createReducer(initialState, {
  [updateSessionSecret.type]: (state: AuthState, action: PayloadAction<string>) => ({
    ...state,
    sessionSecret: action.payload,
  }),
  [updateUser.type]: (state: AuthState, action: PayloadAction<User>) => ({
    ...state,
    user: action.payload,
  }),
  [logout.type]: (state: AuthState) => ({
    ...state,
    user: null,
    sessionSecret: '',
  }),
  [setAppLoading.type]: (state: AuthState, action: PayloadAction<boolean>) => ({
    ...state,
    appLoading: action.payload,
  }),
});

export default authReducer;
