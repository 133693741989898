import Button from '@mui/material/Button';
import { styled } from '@mui/system';

interface ButtonProps {
  width?: string;
  variant?: 'contained' | 'outlined';
}

export const ButtonStyled = styled(Button)<ButtonProps>`
  color: ${(props) => props.variant === 'contained' ? 'white' : '#979797'} ;
  background-color: ${(props) => props.variant === 'contained' ? '#4B4B4B' : '#FFFFFF'};
  border: none;
  height: 37px;
  width: ${(props) => props.width || 'auto'};
  min-width: ${(props) => props.width || '73px'};
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 6px;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  &:disabled {
    background-color: #A6A6A6;
    color: #CFCFCF;
  }
  &:hover {
    background-color: ${(props) => props.variant === 'contained' ? '#4B4B4B' : '#FFFFFF'};
    border: none;
  }
`;
