import type { ChangeEvent } from 'react';
import type { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { HeadingRowContainer } from './TableCreateEditModalBodyRow.styles';
import { options } from './TableCreateEditModalBodyRow';
import { TableRowStyle } from 'types/Tables';

interface HeadingRowProps {
  headingText: string;
  selectedStyle: TableRowStyle;
  setHeadingText: (value: string) => void;
  changeSelectedStyle: (event: SelectChangeEvent) => void;
}

const HeadingRow = ({
  headingText,
  selectedStyle,
  setHeadingText,
  changeSelectedStyle,
}: HeadingRowProps) => (
  <HeadingRowContainer>
    <TextField
      label="Heading Text"
      placeholder="Heading Text"
      variant="standard"
      value={headingText}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setHeadingText(e.target.value)}
    />
    <FormControl fullWidth>
      <InputLabel style={{ textAlign: 'left' }}>Style</InputLabel>
      <Select
        variant="standard"
        placeholder="Style"
        value={selectedStyle}
        onChange={changeSelectedStyle}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </HeadingRowContainer>
);

export default HeadingRow;
