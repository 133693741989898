import { NameValueItem } from "types/Tables";
import NameValueTable from "../NameValueTable";

interface DealsCreateModalFinancialsProps {
  financials: NameValueItem[];
  pieChart: NameValueItem[];
  setFinancials: (items: NameValueItem[]) => void;
  setPieChart: (items: NameValueItem[]) => void;
}

const DealsCreateModalFinancials = ({
  financials,
  pieChart,
  setPieChart,
  setFinancials,
}: DealsCreateModalFinancialsProps) => {
  return (
    <div>
      <NameValueTable
        title="Financials Table"
        items={financials}
        setItems={setFinancials}
        allowEmptyValue
      />
      <NameValueTable
        title="Allocation Pie Chart"
        items={pieChart}
        setItems={setPieChart}
        allowEmptyValue
      />
    </div>
  );
}

export default DealsCreateModalFinancials;
