import { styled } from '@mui/system';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TableRow,
  TableCell,
  tableCellClasses,
} from '@mui/material';

export const DealDetailsModalStyled = styled(Dialog)`
  padding: 0;
`;

export const DealDetailsModalHeader = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  padding-left: 46px;
  padding-right: 26px;
  background-color: #1e1e1d;
  color: white;
  font-size: 21px;
  font-weight: 700;
`;

export const DealDetailsModalClose = styled('div')`
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

export const DealDetailsModalBody = styled(DialogContent)`
  padding: 0;
  height: 50vh;
`;

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#e3e3e3',
    color: '#737373',
    fontWeight: 700,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f6f6f6',
    color: '#000000',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
