import type { SyntheticEvent } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { EditUserModalNotificationsStyled } from './EditUserModalNotifications.styles';

interface EditUserModalNotificationsProps {
  emailNewDeal: boolean;
  emailPaymentClaim: boolean;
  emailDealUpdate: boolean;
  setEmailNewDeal: (value: boolean) => void;
  setEmailPaymentClaim: (value: boolean) => void;
  setEmailDealUpdates: (value: boolean) => void;
}

const EditUserModalNotifications = ({
  emailNewDeal,
  emailPaymentClaim,
  emailDealUpdate,
  setEmailNewDeal,
  setEmailPaymentClaim,
  setEmailDealUpdates,
}: EditUserModalNotificationsProps) => (
  <EditUserModalNotificationsStyled role="tabpanel">
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={emailNewDeal}
            onChange={(_: SyntheticEvent, val: boolean) => setEmailNewDeal(val)}
          />
        }
        label="New deals and site news"
      />
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={emailPaymentClaim}
            onChange={(_: SyntheticEvent, val: boolean) => setEmailPaymentClaim(val)}
          />
        }
        label="Payment available to claim"
      /> */}
      <FormControlLabel
        control={
          <Checkbox
            checked={emailDealUpdate}
            onChange={(_: SyntheticEvent, val: boolean) => setEmailDealUpdates(val)}
          />
        }
        label="Updates on user’s deals"
      />
    </FormGroup>
  </EditUserModalNotificationsStyled>
);

export default EditUserModalNotifications;
