import { HTMLAttributes, useEffect } from 'react';
import { useState } from 'react';
import {
  DealsCreateModalImagePlaceholder,
  DealsCreateModalImageRemove,
  DealsCreateModalImageStyled,
  Loader,
} from './DealsCreateModalImage.styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface DealsCreateModalImageProps extends HTMLAttributes<HTMLImageElement> {
  src?: string;
  onRemove?: () => void;
}

const DealsCreateModalImage = ({ src, onRemove, ...otherProps }: DealsCreateModalImageProps) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);

  useEffect(() => {
    if (src) {
      setLoaded(false);
      setFailed(false);
    }
  }, [src]);

  return (
    <DealsCreateModalImageStyled>
      {!failed && src && (
        <img
          src={src}
          onLoad={() => setLoaded(true)}
          onError={() => setFailed(true)}
          key={src}
          alt=""
          {...otherProps}
        />
      )}
      {(!src || failed) && (
        <DealsCreateModalImagePlaceholder>
          No Image
        </DealsCreateModalImagePlaceholder>
      )}
      {!loaded && !failed && src && <Loader />}
      <DealsCreateModalImageRemove onClick={onRemove}>
        <DeleteOutlineIcon />
      </DealsCreateModalImageRemove>
    </DealsCreateModalImageStyled>
  );
}

export default DealsCreateModalImage;