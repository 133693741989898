import type { PortfolioFund } from '../PortfolioOverview';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  PortfolioTableStyled,
  StyledTableCell,
  StyledTableRow,
} from '../../PortfolioTable.styles';
import { formatToCurrencyString } from 'utils/helpers';

interface PortfolioTableProps {
  funds: PortfolioFund[];
}

const PortfolioTable = ({ funds }: PortfolioTableProps) => {
  return (
    <PortfolioTableStyled>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Deal Name</StyledTableCell>
              <StyledTableCell>Invested</StyledTableCell>
              <StyledTableCell>Total Payout</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {funds.map((fund) => (
              <StyledTableRow key={fund.id}>
                <StyledTableCell component="th" scope="row">
                  {fund.name}
                </StyledTableCell>
                <StyledTableCell>{formatToCurrencyString(fund.invested)}</StyledTableCell>
                <StyledTableCell>{formatToCurrencyString(fund.payouts)}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PortfolioTableStyled>
  );
}

export default PortfolioTable;
