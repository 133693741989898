import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';

import { type Table, TableType } from 'types/Tables';
import {
  type TablesError,
  setShowTermCreateModal,
  setLoading,
  setShowFinancialCreateModal,
  setTableToEdit,
  setActiveSection,
  setTotal,
  setTables,
  setError,
  setPage,
  setRowsPerPage,
} from './actions';

export interface TermsState {
  error: TablesError;
  tables: Table[];
  rowsPerPage: number;
  page: number;
  total: number;
  tableToEdit: Table | null;
  activeSection: TableType;
  showCreateTermModal: boolean;
  showCreateFinancialModal: boolean;
  loading: boolean;
}

const initialState: TermsState = {
  tables: [],
  rowsPerPage: 10,
  page: 0,
  total: 0,
  tableToEdit: null,
  activeSection: TableType.TERM,
  error: null,
  showCreateTermModal: false,
  showCreateFinancialModal: false,
  loading: false,
};

const termsReducer = createReducer(initialState, {
  [setError.type]: (state: TermsState, action: PayloadAction<TablesError>) => ({
    ...state,
    error: action.payload,
  }),
  [setTables.type]: (state: TermsState, action: PayloadAction<Table[]>) => ({
    ...state,
    tables: action.payload,
  }),
  [setTotal.type]: (state: TermsState, action: PayloadAction<number>) => ({
    ...state,
    total: action.payload,
  }),
  [setPage.type]: (state: TermsState, action: PayloadAction<number>) => ({
    ...state,
    page: action.payload,
  }),
  [setRowsPerPage.type]: (state: TermsState, action: PayloadAction<number>) => ({
    ...state,
    rowsPerPage: action.payload,
  }),
  [setShowTermCreateModal.type]: (state: TermsState, action: PayloadAction<boolean>) => ({
    ...state,
    showCreateTermModal: action.payload,
  }),
  [setShowFinancialCreateModal.type]: (state: TermsState, action: PayloadAction<boolean>) => ({
    ...state,
    showCreateFinancialModal: action.payload,
  }),
  [setTableToEdit.type]: (state: TermsState, action: PayloadAction<Table>) => ({
    ...state,
    tableToEdit: action.payload,
  }),
  [setActiveSection.type]: (state: TermsState, action: PayloadAction<TableType>) => ({
    ...state,
    activeSection: action.payload,
  }),
  [setLoading.type]: (state: TermsState, action: PayloadAction<boolean>) => ({
    ...state,
    loading: action.payload,
  }),
});

export default termsReducer;

