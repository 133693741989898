import { DealDetailsInfoStyled, DealDetailsInfoRow } from './DealDetailsInfo.styles';

const DealDetailsInfo = () => {
  return (
    <DealDetailsInfoStyled>
      <div>
        <DealDetailsInfoRow>
          <span>Name: </span>
          Deal Name
        </DealDetailsInfoRow>
        <DealDetailsInfoRow>
          <span>Closing Date: </span>
          10/10/2022, 8:00PM PST
        </DealDetailsInfoRow>
        <DealDetailsInfoRow>
          <span>Invested: </span>
          10/10/2022, 8:00PM PST
        </DealDetailsInfoRow>
      </div>
      <div>
        <DealDetailsInfoRow>
          <span>Raise: </span>
          $1,000,000
        </DealDetailsInfoRow>
        <DealDetailsInfoRow>
          <span>Interval: </span>
          $10,000
        </DealDetailsInfoRow>
        <DealDetailsInfoRow>
          <span>Invested: </span>
          $600,000
        </DealDetailsInfoRow>
      </div>
    </DealDetailsInfoStyled>
  );
}

export default DealDetailsInfo;
