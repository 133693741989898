import { RootState } from 'store';

export const selectDealsError = (state: RootState) => state.deals.error;
export const selectDeals = (state: RootState) => state.deals.deals;
export const selectDealsTotal = (state: RootState) => state.deals.dealsTotal;
export const selectDealsLoading = (state: RootState) => state.deals.dealsLoading;
export const selectDealsCreateLoading = (state: RootState) => state.deals.dealsCreateLoading;
export const selectShowDealsCreateModal = (state: RootState) => state.deals.showDealsCreateModal;
export const selectDealToEdit = (state: RootState) => state.deals.dealToEdit;
export const selectDealType = (state: RootState) => state.deals.dealType;
