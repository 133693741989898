import { styled } from '@mui/system';
import { Paper } from '@mui/material';

export const PaperStyled = styled(Paper)`
  padding: 12px 25px 15px 13px;
  margin-bottom: 14px;
`;

export const NameValueTableTitle = styled('div')`
  font-size: 16px;
  font-weight: 700;
  padding-left: 14px;
  margin-bottom: 6px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`;

export const NameValueTableRow = styled('div')`
  display: grid;
  grid-template-columns: 1.5fr 1fr max-content;
  column-gap: 21px;
`;
