import type { AxiosError } from 'axios';
import { type CoreError, setSnackbarError } from 'store/core/actions';
import { store } from 'store';
import { ERRORS } from 'utils/constants';

export const showError = (error: AxiosError<CoreError>): void => {
  if (error?.response?.data?.message) {
    store.dispatch(setSnackbarError(error.response?.data?.message));
  } else if (error?.response?.data && typeof error?.response?.data === 'string') {
    store.dispatch(setSnackbarError(error?.response?.data));
  } else {
    store.dispatch(setSnackbarError(ERRORS.DEFAULT));
  }
}
