import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from 'hooks';

import { selectSessionUser } from 'store/auth/selectors';

interface ProtectedRouteProps {
    children?: JSX.Element;
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
    const user = useAppSelector(selectSessionUser);
    if (user) {
        return children ? children : <Outlet />;
    }

    return <Navigate to="/login" replace />;
}

export default ProtectedRoute;
