import { styled } from '@mui/system';

export const EditUserModalDemographicsStyled = styled('div')`
  padding: 10px 21px 0 21px;
`;

export const EditUserModalDemographicsName = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
  margin-bottom: 30px;
`;

export const EditUserModalDemographicsCityStateZip = styled('div')`
  display: grid;
  grid-template-columns: 1fr 80px 1fr;
  column-gap: 43px;
  margin-top: 30px;
`;

export const EditUserModalDemographicsPhoneNumber = styled(EditUserModalDemographicsCityStateZip)``;
