import type { AxiosError, AxiosResponse } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';
import type User from 'types/User';
import type { SudraniaResponse } from 'types/Sudrania';
import type { Portfolio } from 'types/Portfolio';
import type { CreateUserParams } from 'api';
import type { SearchUsersParams } from 'api/interfaces/users/searchUsers';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as api from 'api';

import * as usersActions from './actions';
import { AccountMetadata, ChangeUserPasswordParams, GetAccountMetadataParams, AccountNotifications } from 'types/User';
import { showError } from 'utils/helpers/showError';
import { CoreError, setSnackbarError } from 'store/core/actions';

function* createUser(action: PayloadAction<CreateUserParams>) {
  try {
    yield put(usersActions.setCreateUserLoading(true));
    yield call(api.createUser, action.payload);
    yield put(usersActions.searchUsers({
      offset: 0,
      count: 10,
      search: '',
    }));
    yield put(usersActions.setCreateUserLoading(false));
    yield put(usersActions.setShowCreateUserModal(false));
  }
  catch (error: any) {
    yield put(usersActions.setCreateUserLoading(false));
    yield put(usersActions.setError(error?.response?.data as api.ApiError));
    showError(error as AxiosError<CoreError>);
  }
}

function* searchUsers(action: PayloadAction<SearchUsersParams>) {
  try {
    yield put(usersActions.setUsersLoading(true));
    const res: AxiosResponse<{ objects: User[], total: number }> =
      yield call(api.searchUsers, action.payload);
    yield put(usersActions.setUsersLoading(false));
    yield put(usersActions.setUsers(res.data?.objects));
    yield put(usersActions.setTotalUsers(res.data?.total))
  }
  catch (error) {
    yield put(usersActions.setUsersLoading(false));
    showError(error as AxiosError<CoreError>);
  }
}

function* removeUser(action: PayloadAction<string>) {
  try {
    yield put(usersActions.setUsersLoading(true));
    yield call(api.deleteUser, action.payload);
    yield put(usersActions.setUsersLoading(false));
    yield put(usersActions.searchUsers({
      offset: 0,
      count: 10,
      search: '',
    }));
  } catch (error) {
    yield put(usersActions.setUsersLoading(false));
    showError(error as AxiosError<CoreError>);
  }
}

function* removeMultipleUsers(action: PayloadAction<string[]>) {
  try {
    yield put(usersActions.setUsersLoading(true));
    yield all(
      action.payload.map(
        (userId: string) => call(api.deleteUser, userId),
      ),
    )
    yield put(usersActions.setUsersLoading(false));
    yield put(usersActions.searchUsers({
      offset: 0,
      count: 10,
      search: '',
    }));
  } catch (error) {
    yield put(usersActions.setUsersLoading(false));
    showError(error as AxiosError<CoreError>);
  }
}

function* updateUser(action: PayloadAction<{ userId: string, params: CreateUserParams }>) {
  try {
    yield put(usersActions.setEditUserLoading(true));
    yield call(api.updateUser, action.payload.userId, action.payload.params);
    yield put(usersActions.searchUsers({
      offset: 0,
      count: 10,
      search: '',
    }));
    yield put(usersActions.setEditUserLoading(false));
    yield put(usersActions.setShowEditUserModal(false));
  } catch (error: any) {
    yield put(usersActions.setEditUserLoading(false));
    yield put(usersActions.setError(error?.response?.data as api.ApiError));
    showError(error as AxiosError<CoreError>);
  }
}

function* changeUserPassword(action: PayloadAction<ChangeUserPasswordParams>) {
  try {
    yield put(usersActions.setEditUserLoading(true));
    yield call(api.changeUserPassword, action.payload);
    yield put(usersActions.setEditUserLoading(false));
  } catch(error: any) {
    yield put(usersActions.setEditUserLoading(false));
    yield put(usersActions.setError(error?.response?.data as api.ApiError));
    showError(error as AxiosError<CoreError>);
  }
}

function* updateUserMetadata(action: PayloadAction<{ data: Partial<AccountMetadata>, userId: string }>) {
  try {
    yield put(usersActions.setEditUserLoading(true));
    yield call(api.updateUserMetadata, action.payload.data, action.payload.userId);
    yield put(usersActions.setEditUserLoading(false));
  } catch(error: any) {
    yield put(usersActions.setEditUserLoading(false));
    yield put(usersActions.setError(error?.response?.data as api.ApiError));
    showError(error as AxiosError<CoreError>);
  }
}

function* getUserNotifications(action: PayloadAction<string>) {
  try {
    const res: AxiosResponse = yield call(api.getUserNotifications, action.payload);
    console.log(res);
  } catch(error: any) {
    yield put(usersActions.setError(error?.response?.data as api.ApiError));
    showError(error as AxiosError<CoreError>);
  }
}

function* updateUserNotifications(action: PayloadAction<{ data: AccountNotifications, userId: string }>) {
  try {
    const res: AxiosResponse =
      yield call(api.updateAccountNotifications, action.payload.data, action.payload.userId);
    console.log(res);
  } catch(error: any) {
    yield put(usersActions.setError(error?.response?.data as api.ApiError));
    showError(error as AxiosError<CoreError>);
  }
}

function* getUserMetadata(action: PayloadAction<GetAccountMetadataParams>) {
  try {
    yield put(usersActions.setUserMetadataLoading(true));
    const res: AxiosResponse<AccountMetadata> =
      yield call(api.getUserMetadata, action.payload);
    yield put(usersActions.setUserMetadata(res.data));
    yield put(usersActions.setUserMetadataLoading(false));
  } catch(error: any) {
    yield put(usersActions.setUserMetadataLoading(false));
    yield put(usersActions.setError(error?.response?.data as api.ApiError));
  }
}

function* getUserPortfolio(action: PayloadAction<string>) {
  try {
    yield put(usersActions.setPortfolioLoading(true));
    const res: AxiosResponse<SudraniaResponse<Portfolio>> =
      yield call(api.getPortfolio, action.payload);
    if (res.data?.status !== 'Error') {
      yield put(usersActions.setPortfolio(res.data.data));
    }
    yield put(usersActions.setPortfolioLoading(false));
  } catch(error: any) {
    yield put(usersActions.setPortfolioLoading(false));
    yield put(usersActions.setError(error?.response?.data as api.ApiError));
  }
}

function* openFormidiumForUser(action: PayloadAction<string>) {
  try {
    yield put(usersActions.setUserMetadataLoading(true));
    const res: AxiosResponse<AccountMetadata> =
      yield call(api.getUserMetadata, { user_id: action.payload });
    if (res.data.sudrania_id) {
      window.open(`https://csduat.formidium.com/formcontrol/form/edit/${res.data.sudrania_id}/5ccdd3924490102148fa77fb?view=1`);
    } else {
      yield put(setSnackbarError('This user doesn\'t have selected fund'));
    }
    yield put(usersActions.setUserMetadataLoading(false));
  } catch(error: any) {
    console.log(error);
    yield put(usersActions.setUserMetadataLoading(false));
    yield put(usersActions.setError(error?.response?.data as api.ApiError));
    showError(error);
  }
}

function* usersSaga() {
  yield all([
    takeLatest(usersActions.createUser.type, createUser),
    takeLatest(usersActions.searchUsers.type, searchUsers),
    takeLatest(usersActions.updateUser.type, updateUser),
    takeEvery(usersActions.removeUser.type, removeUser),
    takeEvery(usersActions.removeMultipleUsers.type, removeMultipleUsers),
    takeEvery(usersActions.changeUserPassword.type, changeUserPassword),
    takeEvery(usersActions.getUserMetadata.type, getUserMetadata),
    takeEvery(usersActions.updateUserMetadata.type, updateUserMetadata),
    takeEvery(usersActions.updateUserNotifications.type, updateUserNotifications),
    takeEvery(usersActions.getUserNotifications.type, getUserNotifications),
    takeEvery(usersActions.getPortfolio.type, getUserPortfolio),
    takeEvery(usersActions.openFormidiumForUser.type, openFormidiumForUser),
  ]);
}

export default usersSaga;
