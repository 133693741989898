import type { ApiError } from 'api';
import { createAction } from '@reduxjs/toolkit';

const PREFIX = 'core';

export type CoreError = ApiError | null;

export const setError = createAction<CoreError>(`${PREFIX}/setError`);
export const setSnackbarError = createAction<string>(`${PREFIX}/setSnackbarError`);
export const clearError = createAction(`${PREFIX}/clearError`);
export const clearSnackbarError = createAction(`${PREFIX}/clearSnackbarError`);
