import { styled } from '@mui/system';
import { Button } from '@mui/material';

interface ButtonStyledProps {
  selected?: boolean;
}

export const ButtonStyled = styled(Button)<ButtonStyledProps>`
  min-width: 70px!important;
  height: 37px;
  font-size: 14px;
  padding: 0 10px;
  border: 1px solid #d2d2d2;
  color: black;
  text-transform: initial;
  background-color: ${(props) => props.selected ? '#d2d2d2' : 'transparent'};
  &:hover {
    border: 1px solid #d2d2d2;
    background-color: ${(props) => props.selected ? '#d2d2d2' : 'transparent'};
  }
`;
