import type { AxiosError, AxiosResponse } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { ArrayResponseWithTotalInfo } from 'types/common';
import type { CreateDealRequest, Deal, GetDealsRequest } from 'types/Deals';
import type { CoreError } from 'store/core/actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as dealsActions from './actions';

import * as api from 'api';
import { showError } from 'utils/helpers/showError';
import { store } from 'store';
import { selectDealType } from './selectors';

function* getDeals(action: PayloadAction<GetDealsRequest>) {
  try {
    yield put(dealsActions.setDealsLoading(true));
    const res: AxiosResponse<ArrayResponseWithTotalInfo<Deal[]>> =
      yield call(api.getDeals, action.payload);
    yield put(dealsActions.setDealsTotal(+res.data.total));
    yield put(dealsActions.setDeals(res.data.objects));
    yield put(dealsActions.setDealsLoading(false));
  }
  catch (error: any) {
    yield put(dealsActions.setDealsLoading(false));
  }
}

function* createDeal(action: PayloadAction<CreateDealRequest>) {
  try {
    yield put(dealsActions.setDealsCreateLoading(true));
    yield call(api.createDeal, action.payload);
    yield put(dealsActions.setDealsCreateLoading(false));
    yield put(dealsActions.setShowDealsCreateModal(false));
    yield put(dealsActions.getDeals({
      offset: 0,
      total: 10,
    }));
  }
  catch (error: any) {
    yield put(dealsActions.setDealsCreateLoading(false));
    showError(error as AxiosError<CoreError>);
  }
}

function* editDeal(action: PayloadAction<{ id: string, data: Omit<Deal, 'id'> }>) {
  try {
    const state = store.getState();
    const dealType = selectDealType(state);
    yield put(dealsActions.setDealsCreateLoading(true));
    yield call(api.editDeal, action.payload.id, action.payload.data);
    yield put(dealsActions.setDealsCreateLoading(false));
    yield put(dealsActions.setShowDealsCreateModal(false));
    yield put(dealsActions.setDealToEdit(null));
    yield put(dealsActions.getDeals({
      offset: 0,
      total: 10,
      open: dealType === 'all' ? null : dealType === 'open',
    }));
  }
  catch (error: any) {
    yield put(dealsActions.setDealsCreateLoading(false));
    yield put(dealsActions.setShowDealsCreateModal(false));
    yield put(dealsActions.setDealToEdit(null));
    showError(error as AxiosError<CoreError>);
  }
}

function* deleteDeal(action: PayloadAction<string>) {
  try {
    yield put(dealsActions.setDealsLoading(true));
    yield call(api.deleteDeal, action.payload);
    yield put(dealsActions.setDealsLoading(false));
    yield put(dealsActions.getDeals({
      offset: 0,
      total: 10,
      open: false,
    }));
  }
  catch (error: any) {
    yield put(dealsActions.setDealsLoading(false));
    showError(error as AxiosError<CoreError>);
  }
}

function* deleteMultipleDeals(action: PayloadAction<string[]>) {
  try {
    yield put(dealsActions.setDealsLoading(true));
    yield all(action.payload.map(
      (dealId: string) => call(api.deleteDeal, dealId),
    ))
    yield put(dealsActions.setDealsLoading(false));
    yield put(dealsActions.getDeals({
      offset: 0,
      total: 10,
      open: false,
    }));
  }
  catch (error: any) {
    yield put(dealsActions.setDealsLoading(false));
    showError(error as AxiosError<CoreError>);
  }
}

function* dealsSaga() {
  yield all([
    takeLatest(dealsActions.getDeals.type, getDeals),
    takeLatest(dealsActions.createDeal.type, createDeal),
    takeLatest(dealsActions.editDeal.type, editDeal),
    takeLatest(dealsActions.deleteDeal.type, deleteDeal),
    takeLatest(dealsActions.deleteMultipleDeals.type, deleteMultipleDeals),
  ]);
}

export default dealsSaga;