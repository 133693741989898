import type { ChangeEvent } from 'react'
import type { Deal } from 'types/Deals';
import { DealType } from 'store/deals/reducer';
import { useState, useCallback, useEffect, useRef } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DealsHeader from 'components/pages/Deals/DealsHeader';
import { DealsLoader, DealsStyled, ViewSudraniaLink } from './Deals.styles';
import Button from 'components/common/Button';
import DealsCreateModal from 'components/pages/Deals/DealsCreateModal';
import DealDetailsModal from 'components/pages/Deals/DealDetailsModal';
import { useAppDispatch, useAppSelector } from 'hooks';
import { deleteDeal, deleteMultipleDeals, getDeals, setDealToEdit, setDealType, setShowDealsCreateModal } from 'store/deals/actions';
import { selectDeals, selectDealsLoading, selectDealsTotal, selectDealType } from 'store/deals/selectors';
import ConfirmModal from 'components/common/ConfirmModal';

const Deals = () => {
  const dispatch = useAppDispatch();

  const deals = useAppSelector(selectDeals);
  const dealsLoading = useAppSelector(selectDealsLoading);
  const dealsTotal = useAppSelector(selectDealsTotal);
  const dealType = useAppSelector(selectDealType);

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedDeals, setSelectedDeals] = useState<Deal[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [showDealsDetailsModal, setShowDealsDetailsModal] =
    useState<boolean>(false);
  const [dealToRemove, setDealToRemove] = useState<Deal | null>(null);
  const [showDeleteMultipleDealsModal, setShowDeleteMultipleDealsModal] =
    useState<boolean>(false);

  const selectAll = useCallback(() => {
    if (selectedDeals.length > 0) {
      setSelectedDeals([]);
    } else {
      setSelectedDeals(deals);
    }
  }, [deals, selectedDeals]);

  const selectDeal = useCallback((deal: Deal) => {
    const isSelected = selectedDeals.find((d: Deal) => d.id === deal.id);
    if (isSelected) {
      setSelectedDeals(selectedDeals.filter((d) => d.id !== deal.id));
    } else {
      setSelectedDeals([...selectedDeals, deal]);
    }
  }, [selectedDeals]);

  const isSelected = useCallback((user: Deal): boolean => {
    const isSelected = selectedDeals.find((d: Deal) => d.id === user.id);
    return !!isSelected;
  }, [selectedDeals]);

  const acceptDealRemove = useCallback(() => {
    if (dealToRemove) {
      dispatch(deleteDeal(dealToRemove.id))
    }
    setDealToRemove(null);
  }, [dispatch, dealToRemove]);

  const acceptMultipleDealsRemove = useCallback(() => {
    dispatch(deleteMultipleDeals(
      selectedDeals.map((deal) => deal.id),
    ));
    setShowDeleteMultipleDealsModal(false);
  }, [dispatch, selectedDeals]);

  const editDeal = useCallback((deal: Deal) => {
    dispatch(setDealToEdit(deal));
    dispatch(setShowDealsCreateModal(true));
  }, [dispatch]);

  const changeSearchValue = useCallback((value: string) => {
    setSearchValue(value);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      dispatch(getDeals({
        offset: page,
        total: rowsPerPage,
        name_contains: value,
        open: dealType === 'all' ? null : dealType === 'open',
      }));
    }, 1000);
  }, [dispatch, page, dealType, rowsPerPage]);

  useEffect(() => {
    dispatch(getDeals({
      offset: page,
      total: rowsPerPage,
      name_contains: searchValue,
      open: dealType === 'all' ? null : dealType === 'open',
    }));
    // eslint-disable-next-line
  }, [dispatch, page, dealType, rowsPerPage]);

  return (
    <DealsStyled>
      <DealsHeader
        value={dealType}
        searchValue={searchValue}
        disableDeleteSelected={selectedDeals.length === 0}
        deleteSelectedClick={() => setShowDeleteMultipleDealsModal(true)}
        onSearchChange={changeSearchValue}
        onChange={(value: string) => dispatch(setDealType(value as DealType))}
      />
      {dealsLoading && (
        <DealsLoader>
          <CircularProgress size={50} disableShrink />
        </DealsLoader>
      )}
      {!dealsLoading && (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={selectedDeals.length > 0 && selectedDeals.length < deals.length}
                      checked={selectedDeals.length > 0 && selectedDeals.length === deals.length}
                      onChange={selectAll}
                      inputProps={{
                        'aria-label': 'select all deals',
                      }}
                    />
                  </TableCell>
                  <TableCell width="100px">ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Hidden</TableCell>
                  <TableCell>Featured</TableCell>
                  <TableCell align="right" width="50%" />
                </TableRow>
              </TableHead>
              <TableBody>
                {deals.map((deal) => (
                  <TableRow key={deal.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isSelected(deal)}
                        onChange={() => selectDeal(deal)}
                        inputProps={{
                          'aria-label': 'select deal',
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      ...{deal.id.slice(-6)}
                    </TableCell>
                    <TableCell>{deal.name}</TableCell>
                    <TableCell>{deal.hidden ? 'Yes' : ''}</TableCell>
                    <TableCell>{deal.featured ? 'Yes' : ''}</TableCell>
                    <TableCell align="right">
                      <Stack
                        spacing={1}
                        direction="row"
                        justifyContent="flex-end"
                      >
                        <ViewSudraniaLink
                          href={`https://csduat.formidium.com/formcontrol/form/edit/${deal.sudrania_id}/5ccdd3924490102148fa77fb?view=1`}
                          target="__blank"
                          disabled={!deal.sudrania_id}
                        >
                          <Button disabled={!deal.sudrania_id}>
                            Formidium
                          </Button>
                        </ViewSudraniaLink>
                        <Button onClick={() => editDeal(deal)}>Edit</Button>
                        <Button
                          width="37px"
                          onClick={() => setDealToRemove(deal)}
                        >
                          <DeleteOutlinedIcon />
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={dealsTotal}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage: number) => setPage(newPage)}
            onRowsPerPageChange={
              (e: ChangeEvent<HTMLInputElement>) =>
                setRowsPerPage(+e.target.value)
            }
          />
        </>
      )}
      <DealsCreateModal />
      <DealDetailsModal
        open={showDealsDetailsModal}
        onClose={() => setShowDealsDetailsModal(false)}
      />
      <ConfirmModal
        open={!!dealToRemove}
        title={`Are you sure you want to remove ${dealToRemove?.name || dealToRemove?.id}`}
        onClose={() => setDealToRemove(null)}
        onAccept={acceptDealRemove}
      />
      <ConfirmModal
        open={showDeleteMultipleDealsModal}
        title={'Are you sure you want to remove all selected deals?'}
        onClose={() => setShowDeleteMultipleDealsModal(false)}
        onAccept={acceptMultipleDealsRemove}
      />
    </DealsStyled>
  );
}

export default Deals;
