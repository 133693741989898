import { styled } from '@mui/system';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TableRow } from '@mui/material';

export const PortfolioTableStyled = styled('div')`
  max-height: 30vh;
  overflow-y: auto;
`;

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#e3e3e3',
    color: '#737373',
    fontWeight: 700,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f6f6f6',
    color: '#000000',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
