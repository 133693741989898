import { styled } from '@mui/system';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

export const EditUserModalLoading = styled('div')`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EditUserModalTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 45px;
`;

export const EditUserModalTitleText = styled('div')`
  color: black;
  font-size: 21px;
  font-weight: 700;
`;

export const TabsWrapper = styled('div')`
  position: relative;
`;

export const TabsDivider = styled('div')`
  background-color: #dcdcdc;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const EditUserModalActions = styled(DialogActions)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 45px;
  button {
    min-width: 80px;
    margin-right: 20px;
  }
`;
