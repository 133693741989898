import { PortfolioInfoStyled, PortfolioInfoRow } from './PortfolioInfo.styles';

interface PortfolioInfoProps {
  email: string;
  name: string;
  totalInvested: string;
  totalPayouts: string;
  totalProfit: string;
}

const PortfolioInfo = ({
  email,
  name,
  totalInvested,
  totalPayouts,
  totalProfit,
}: PortfolioInfoProps) => {
  return (
    <PortfolioInfoStyled>
      <div>
        <PortfolioInfoRow>
          <span>Name: </span>
          {name}
        </PortfolioInfoRow>
        <PortfolioInfoRow>
          <span>Email: </span>
          {email}
        </PortfolioInfoRow>
        <PortfolioInfoRow>
          <span>Invested: </span>
          {totalInvested}
        </PortfolioInfoRow>
      </div>
      <div>
        <PortfolioInfoRow>
          <span>Total Profit: </span>
          {totalProfit}
        </PortfolioInfoRow>
        <PortfolioInfoRow>
          <span>Total Payout: </span>
          {totalPayouts}
        </PortfolioInfoRow>
      </div>
    </PortfolioInfoStyled>
  );
}

export default PortfolioInfo;
