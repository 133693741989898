import type {
  LoginParams,
  LoginResponse,
} from './interfaces/auth';
import type { AxiosResponse } from 'axios';
import type { AccountMetadata, ChangeUserPasswordParams, default as User, GetAccountMetadataParams, AccountNotifications } from 'types/User';
import type { CreateDealRequest, Deal, GetDealsRequest } from 'types/Deals';
import type { ArrayResponseWithTotalInfo } from 'types/common';
import type { SudraniaFundDetailsRaw, SudraniaFundRaw, SudraniaRequest, SudraniaResponse } from 'types/Sudrania';
import type { GetTablesRequest, Table } from 'types/Tables';
import type { CreateUserParams, SearchUsersParams } from './interfaces/users';

import { api } from './api-base';
import { apiR2D } from './api-base-r2d';
import { apiSudrania } from './api-base-sudrania';

// Auth
export const login = (params: LoginParams): Promise<AxiosResponse<LoginResponse>> =>
  api.post('/session', params);

export const getCurrentUser = (sessionSecret = ''):
  Promise<AxiosResponse<any>> => api.get('/user/me', {
    headers: {
      'Elements-SessionSecret': sessionSecret,
    },
  });


// User
export const searchUsers = (params?: SearchUsersParams): Promise<AxiosResponse<User[]>> =>
  api.get('/user', {
    params: params || {},
  });
export const createUser = (params: CreateUserParams): Promise<AxiosResponse> =>
  api.post('/user', params);
export const updateUser = (userId: string, params: CreateUserParams): Promise<AxiosResponse<CreateUserParams>> =>
  api.put(`/user/${userId}`, params);
export const deleteUser = (userId: string): Promise<AxiosResponse> =>
  api.delete(`/user/${userId}`);
export const getUserMetadata = (params: GetAccountMetadataParams): Promise<AxiosResponse<AccountMetadata>> =>
  apiR2D.get('/account/metadata', {
    params,
  });
export const changeUserPassword = (params: ChangeUserPasswordParams): Promise<AxiosResponse> =>
  apiR2D.put('/account/password', params);
export const updateUserMetadata = (params: Partial<AccountMetadata>, userId: string): Promise<AxiosResponse> =>
  apiR2D.put(`/account/metadata?user_id=${userId}`, params);
export const getUserNotifications = (userId: string): Promise<AxiosResponse> =>
  apiR2D.get(`/account/notifications`, {
    params: {
      user_id: userId,
    }
  });
export const updateAccountNotifications = (params: AccountNotifications, userId: string): Promise<AxiosResponse> =>
  apiR2D.put(`/account/notifications?user_id=${userId}`, params)
export const getAccountProfiles = (userId: string): Promise<AxiosResponse> =>
  api.get(`/profile?user=${userId}`);

// Deals
export const getDeals = (params: GetDealsRequest):
  Promise<AxiosResponse<ArrayResponseWithTotalInfo<Deal[]>>> =>
    apiR2D.get('/deals', {
      params,
    });

export const createDeal = (data: CreateDealRequest):
  Promise<AxiosResponse> => apiR2D.post('/deals', data);
export const editDeal = (id: string, data: Omit<Deal, 'id'>):
  Promise<AxiosResponse> => apiR2D.put(`/deals/${id}`, data);
export const deleteDeal = (dealId: string):
  Promise<AxiosResponse> => apiR2D.delete(`/deals/${dealId}`);

// Sudrania
export const sendSudraniaRequest = (data: SudraniaRequest): Promise<AxiosResponse> => {
  return apiR2D.post('/sudrania', data);
}
export const getFundList = (): Promise<AxiosResponse<SudraniaFundRaw[]>> => {
  return sendSudraniaRequest({
    path: 'csd_fund_api',
    user_agent: 'Mozilla/5.0 (Windows NT 6.1; Win64; x64; rv:47.0) Gecko/20100101 Firefox/47.0',
    verb: 'GET',
  });
}

export const getFundDetails = (fundId: string): Promise<AxiosResponse<SudraniaResponse<SudraniaFundDetailsRaw>>> => {
  return sendSudraniaRequest({
    path: `/csd_fund_details_api`,
    user_agent: 'Mozilla/5.0 (Windows NT 6.1; Win64; x64; rv:47.0) Gecko/20100101 Firefox/47.0',
    verb: 'GET',
    params: JSON.stringify({ id: fundId }),
  });
}

export const getPortfolio = (investor_sr_no: string): Promise<AxiosResponse> =>
  apiSudrania.get('get/portfolio', {
    params: {
      investor_sr_no,
    },
  });

// Terms and Financial
export const getTermsAndFinancials = (params: GetTablesRequest): Promise<AxiosResponse> =>
  apiR2D.get('/tables', {
    params,
  });
export const addTermsAndFinancials = (tables: Table): Promise<AxiosResponse> =>
  apiR2D.post('/tables', tables);
export const getTernsAbdFinancial = (tableId: string): Promise<AxiosResponse> =>
  apiR2D.get(`/tables/${tableId}`);
export const deleteTermsAndFinancials = (tableId: string): Promise<AxiosResponse> =>
  apiR2D.delete(`/tables/${tableId}`);
export const updateTermsAndFinancials = (tableId: string, data: Partial<Table>): Promise<AxiosResponse> =>
  apiR2D.put(`/tables/${tableId}`, data);
