import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DealDetailsInfo from './DealDetailsInfo';
import {
  DealDetailsModalBody,
  DealDetailsModalClose,
  DealDetailsModalHeader,
  DealDetailsModalStyled,
  StyledTableCell,
  StyledTableRow,
} from './DealDetailsModal.styles';

const mockTableData = [
  { user: 'user@domain.com', invested: '$20,000.00', pendingPayout: '$1,000', totalPayouts: '$20,000' },
  { user: 'user@domain.com', invested: '$20,000.00', pendingPayout: '$1,000', totalPayouts: '$20,000' },
  { user: 'user@domain.com', invested: '$20,000.00', pendingPayout: '$1,000', totalPayouts: '$20,000' },
  { user: 'user@domain.com', invested: '$20,000.00', pendingPayout: '$1,000', totalPayouts: '$20,000' }
];

interface DealDetailsModalProps {
  open: boolean;
  onClose: () => void;
}

const DealDetailsModal = ({ open, onClose }: DealDetailsModalProps) => {
  return (
    <DealDetailsModalStyled
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DealDetailsModalHeader>
        <div>Deal Details</div>
        <DealDetailsModalClose onClick={onClose}>
          {'Close [X]'}
        </DealDetailsModalClose>
      </DealDetailsModalHeader>
      <DealDetailsModalBody>
        <DealDetailsInfo />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>User</StyledTableCell>
                <StyledTableCell>Invested</StyledTableCell>
                <StyledTableCell>Pending Payout</StyledTableCell>
                <StyledTableCell>Total Payout</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mockTableData.map((item, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {item.user}
                  </StyledTableCell>
                  <StyledTableCell>{item.invested}</StyledTableCell>
                  <StyledTableCell>{item.pendingPayout}</StyledTableCell>
                  <StyledTableCell>{item.totalPayouts}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DealDetailsModalBody>
    </DealDetailsModalStyled>
  )
}

export default DealDetailsModal;
