import { styled } from '@mui/system';

export const PayoutHistoryWrapper = styled('div')`
  padding: 30px 46px;
`;

export const PayoutHistoryTable = styled('div')`
  max-height: 40vh;
  overflow-y: auto;
`;
