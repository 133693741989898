import type { UseFormRegister } from 'react-hook-form';
import type { DealsCreateFields } from '../DealsCreateModal';
import { TextField } from '@mui/material';
import { TextAreaField } from './DealsCreateModalDescriptions.styles';

interface DealsCreateModalDescriptionsProps {
  register: UseFormRegister<DealsCreateFields>
};

const DealsCreateModalDescriptions = ({ register }: DealsCreateModalDescriptionsProps) => (
  <div>
    <TextField
      defaultValue=""
      variant="standard"
      label="Short Description"
      placeholder="Description that appears on the Market page"
      rows={3}
      multiline
      fullWidth
      {...register('short_description')}
    />
    <TextAreaField>
      <TextField
        defaultValue=""
        variant="standard"
        label="Featured Description"
        placeholder="Description that appears on the Deal page header"
        rows={4}
        multiline
        fullWidth
        {...register('featured_description')}
      />
    </TextAreaField>
    <TextAreaField>
      <TextField
        defaultValue=""
        variant="standard"
        label="Summary"
        placeholder="Detailed description of the opportunity for the Summary tab"
        rows={7}
        multiline
        fullWidth
        {...register('summary')}
      />
    </TextAreaField>
  </div>
);

export default DealsCreateModalDescriptions;
