import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

interface HeaderButtonProps {
  active?: boolean;
}

export const HeaderStyled = styled('div')`
  height: 62px;
  background-color: #1E1E1D;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderNav = styled('div')`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const HeaderNavLink = styled(Link)`
  text-decoration: none;
`;

export const HeaderButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active',
})<HeaderButtonProps>`
  color: ${(props) => props.active ? '#0F9ECE' : 'white'};
  text-transform: capitalize;
  font-weight: 700;
  font-size: 16px;
`;

export const HeaderLogo = styled('img')`
  height: 48px;
  margin-right: 30px;
`;
