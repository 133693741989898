import { useCallback } from 'react';

// Components and Styles
import { ButtonGroup as MuiButtonGroup } from '@mui/material';
import { ButtonStyled } from './ButtonsGroup.styles';

interface ButtonGroupItem {
  label: string;
  value: string;
}

interface ButtonsGroupProps {
  items: ButtonGroupItem[];
  value?: string;
  onChange?: (value: string) => void;
}

const ButtonsGroup = ({ items, value, onChange }: ButtonsGroupProps) => {
  const onSelect = useCallback((itemValue: string) => {
    if (onChange) {
      onChange(itemValue);
    }
  }, [onChange]);

  return (
    <MuiButtonGroup variant="outlined" aria-label="outlined button group">
      {items.map((item: ButtonGroupItem) => (
        <ButtonStyled
          key={item.value}
          selected={value === item.value}
          onClick={() => onSelect(item.value)}
        >
          {item.label}
        </ButtonStyled>
      ))}
    </MuiButtonGroup>
  );
}

export default ButtonsGroup;
