import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

import { store } from 'store';
import { authActions } from 'store/auth';
import { selectSessionSecret } from 'store/auth/selectors';
import { coreActions } from 'store/core';
import { CoreError } from 'store/core/actions';

const UNKNOWN_ERROR_CODE = "UNKNOWN_ERROR_CODE";
const NETWORK_ERROR = "Network Error";
const BASEURL = process.env.REACT_APP_SUDRANIA_URL;

export const apiSudrania: AxiosInstance = axios.create({
    baseURL: BASEURL,
    headers: {
      'content-type': 'application/json',
    },
});

const InterceptorRequestCallback = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const state = store.getState();
  const sessionSecret = selectSessionSecret(state);

  // Set Elements-SessionSecret on request, if it's available in state
  // and if header is not set yet
  if (
    config?.headers &&
    !config.headers['Elements-SessionSecret'] &&
    sessionSecret
  ) {
    config.headers['Elements-SessionSecret'] = sessionSecret;
  }

  // Do something on request
  return config;
};

const InterceptorRequestErrorCallback = (error: AxiosError<CoreError>): Promise<AxiosError> => {
  if (error.response) {
    store.dispatch(coreActions.setError(error.response.data));
  } else {
    store.dispatch(coreActions.setError({
      code: UNKNOWN_ERROR_CODE,
      message: NETWORK_ERROR,
    }));
  }
  // Do something on request error
  return Promise.reject(error);
};

const InterceptorResponseCallback = (response: AxiosResponse): AxiosResponse => {
  // Do something on response
  return response;
};

const InterceptorResponseErrorCallback = (error: AxiosError<CoreError>): Promise<AxiosError> => {
  if (error.response) {
    store.dispatch(coreActions.setError(error.response.data));
    const status = error.response.status;
    switch(status) {
      case 401: {
        // Remove session secret from store
        store.dispatch(authActions.updateSessionSecret(''));
        // TO DO: Redirect to login page, when we have it
        break;
      }
      case 403: {
        break;
      }
    }
  } else {
    store.dispatch(coreActions.setError({
      code: UNKNOWN_ERROR_CODE,
      message: NETWORK_ERROR,
    }));
  }
  // Do something on response error
  return Promise.reject(error);
};

apiSudrania.interceptors.request.use(InterceptorRequestCallback, InterceptorRequestErrorCallback);
apiSudrania.interceptors.response.use(InterceptorResponseCallback, InterceptorResponseErrorCallback);

