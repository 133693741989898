import { DialogActions } from '@mui/material';
import { styled } from '@mui/system';

export const Title = styled('div')`
  width: 450px;
`;

export const DialogActionsStyled = styled(DialogActions)`
  padding: 16px 24px;
  display: flex;
  justify-content: flex-start;
`;
