import { styled } from '@mui/system';

export const TableCreateEditModalBodyContainer = styled('div')`
  border-top: 1px solid #979797;
  border-bottom: 1px solid #979797;
  max-height: 50vh;
  overflow-y: auto;
  padding: 31px 46px 60px 42px;
  background-color: #F7F7F7;
`;

export const TableCreateEditModalBodyTitle = styled('div')`
  color: black;
  margin-bottom: 11px;
  font-weight: 700;
  font-size: 16px;
`;

export const TableCreateEditModalBodyActions = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 11px;
`;



export const TableCreateEditModalBodyError = styled('div')`
  color: red;
  font-size: 14px;
  text-align: right;
`;
