import { TextField } from "@mui/material";
import Button from "components/common/Button";
import { ChangeEvent, useCallback, useState } from "react";
import { NameValueTableRow, NameValueTableTitle, PaperStyled } from "./NameValueTable.styles";

interface NameValueItem {
  name: string;
  value: string;
}

interface NameValueTableProps {
  title: string;
  items: NameValueItem[];
  allowEmptyValue?: boolean;
  setItems: (items: NameValueItem[]) => void;
}

const NameValueTable = ({
  title,
  items,
  allowEmptyValue,
  setItems,
}: NameValueTableProps) => {
  const [newName, setNewName] = useState('');
  const [newValue, setNewValue] = useState('');

  const addNewHeaderTableItem = useCallback(() => {
    const newHeaderTableItems = [...items];
    newHeaderTableItems.push({
      name: newName,
      value: newValue,
    });
    setItems(newHeaderTableItems);
    setNewName('');
    setNewValue('');
  }, [newName, newValue, items, setItems]);

  const removeHeaderTableItem = useCallback((index: number) => {
    const newHeaderTableItems = items.filter((_, i) => index !== i);
    setItems(newHeaderTableItems);
  }, [items, setItems]);

  const changeNewName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  }, []);

  const changeNewValue = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.target.value);
  }, []);

  const changeName = useCallback((e: ChangeEvent<HTMLInputElement>, index: number) => {
    setItems(items.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          name: e.target.value,
        };
      }
      return item;
    }));
  }, [items, setItems]);

  const changeValue = useCallback((e: ChangeEvent<HTMLInputElement>, index: number) => {
    setItems(items.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          value: e.target.value,
        };
      }
      return item;
    }));
  }, [items, setItems]);

  return (
    <div>
      <NameValueTableTitle>{title}</NameValueTableTitle>
      <PaperStyled>
        {items.map((item, index) => (
          <NameValueTableRow key={index}>
            <TextField
              label="Name"
              placeholder=""
              variant="standard"
              value={item.name}
              onChange={
                (e: ChangeEvent<HTMLInputElement>) => changeName(e, index)
              }
              fullWidth
            />
            <TextField
              label="Value"
              placeholder=""
              variant="standard"
              value={item.value}
              onChange={
                (e: ChangeEvent<HTMLInputElement>) => changeValue(e, index)
              }
              fullWidth
            />
            <Button width="37px" onClick={() => removeHeaderTableItem(index)}>-</Button>
          </NameValueTableRow>
        ))}
        <NameValueTableRow>
          <TextField
            label="Name"
            placeholder=""
            variant="standard"
            value={newName}
            onChange={changeNewName}
            fullWidth
          />
          <TextField
            label="Value"
            placeholder=""
            variant="standard"
            value={newValue}
            onChange={changeNewValue}
            fullWidth
          />
          <Button
            width="37px"
            disabled={(!newValue && !allowEmptyValue) || !newName}
            onClick={addNewHeaderTableItem}
          >
            +
          </Button>
        </NameValueTableRow>
      </PaperStyled>
    </div>
  );
}

export default NameValueTable;
