import type { PayloadAction } from '@reduxjs/toolkit';
import type { Deal } from 'types/Deals';
import {
  type DealsError,
  setDealsCreateLoading,
  setDealsTotal,
  setDealToEdit,
  setDealType,
  setShowDealsCreateModal,
} from './actions';
import { setDeals, setDealsLoading } from './actions';
import { createReducer } from '@reduxjs/toolkit';
import { setError } from './actions';

export type DealType = 'all' | 'open' | 'close';

export interface DealsState {
  deals: Deal[];
  dealsLoading: boolean;
  dealsTotal: number;
  dealsCreateLoading: boolean;
  dealType: DealType;
  showDealsCreateModal: boolean;
  dealToEdit: Deal | null;
  error: DealsError | null;
}

const initialState: DealsState = {
  deals: [],
  dealsLoading: false,
  dealsTotal: 0,
  dealsCreateLoading: false,
  dealToEdit: null,
  dealType: 'all',
  showDealsCreateModal: false,
  error: null,
};

const dealsReducer = createReducer(initialState, {
  [setError.type]: (state: DealsState, action: PayloadAction<DealsError>) => ({
    ...state,
    error: action.payload,
  }),
  [setDealsLoading.type]: (state: DealsState, action: PayloadAction<boolean>) => ({
    ...state,
    dealsLoading: action.payload,
  }),
  [setDeals.type]: (state: DealsState, action: PayloadAction<Deal[]>) => ({
    ...state,
    deals: action.payload,
  }),
  [setDealsTotal.type]: (state: DealsState, action: PayloadAction<number>) => ({
    ...state,
    dealsTotal: action.payload,
  }),
  [setDealsCreateLoading.type]: (state: DealsState, action: PayloadAction<boolean>) => ({
    ...state,
    dealsCreateLoading: action.payload,
  }),
  [setShowDealsCreateModal.type]: (state: DealsState, action: PayloadAction<boolean>) => ({
    ...state,
    showDealsCreateModal: action.payload,
  }),
  [setDealToEdit.type]: (state: DealsState, action: PayloadAction<Deal>) => ({
    ...state,
    dealToEdit: action.payload,
  }),
  [setDealType.type]: (state: DealsState, action: PayloadAction<DealType>) => ({
    ...state,
    dealType: action.payload,
  }),
});

export default dealsReducer;
