import type { RootState } from 'store';

export const selectUsersError = (state: RootState) => state.users.error;
export const selectShowCreateUserModal = (state: RootState) => state.users.showCreateUserModal;
export const selectShowEditUserModal = (state: RootState) => state.users.showEditUserModal;
export const selectUsers = (state: RootState) => state.users.users;
export const selectTotalUsers = (state: RootState) => state.users.totalUsers;
export const selectUsersLoading = (state: RootState) => state.users.usersLoading;
export const selectCreateUserLoading = (state: RootState) => state.users.createUserLoading;
export const selectEditUserLoading = (state: RootState) => state.users.editUserLoading;
export const selectSelectedUser = (state: RootState) => state.users.selectedUser;
export const selectUserMetadataLoading = (state: RootState) => state.users.userMetadataLoading;
export const selectUserMetadata = (state: RootState) => state.users.userMetadata;
export const selectUserPortfolio = (state: RootState) => state.users.portfolio;
export const selectUserPortfolioLoading = (state: RootState) => state.users.portfolioLoading;
