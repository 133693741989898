import { ChangeEvent } from 'react';
import { TextField } from '@mui/material';

import {
  FeaturedImage,
  PaperStyled
} from './DealsCreateModalImages.styles';
import DealsCreateModalImage from './DealsCreateModalImage/DealsCreateModalImage';
import { DealHeaderTableItem } from 'types/Deals';
import NameValueTable from '../NameValueTable';

interface DealsCreateModalImagesProps {
  featuredImage: string;
  headerTable: DealHeaderTableItem[],
  setHeaderTable: (items: DealHeaderTableItem[]) => void;
  setFeaturedImage: (value: string) => void;
}

const DealsCreateModalImages = ({
  featuredImage,
  headerTable,
  setFeaturedImage,
  setHeaderTable,
}: DealsCreateModalImagesProps) => (
  <div>
    <PaperStyled>
      <FeaturedImage>
        <TextField
          value={featuredImage}
          onChange={
            (e: ChangeEvent<HTMLInputElement>) =>
              setFeaturedImage(e.target.value)
          }
          label="Featured Image"
          placeholder="Image url..."
          variant="standard"
          fullWidth
        />
        <DealsCreateModalImage
          src={featuredImage}
          onRemove={() => setFeaturedImage('')}
        />
      </FeaturedImage>
    </PaperStyled>
    <NameValueTable
      title="Header Table"
      items={headerTable}
      setItems={setHeaderTable}
    />
  </div>
);

export default DealsCreateModalImages;
