import type User from 'types/User';
import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useState, useRef } from 'react';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CircularProgress from '@mui/material/CircularProgress';

// Components and Styles
import { UersHead, UsersLoader, UsersStyled } from './Users.styles';
import Button from 'components/common/Button';
import CreateUserModal from 'components/pages/Users/CreateUserModal';
import ConfirmModal from 'components/common/ConfirmModal';
import EditUserModal from 'components/pages/Users/EditUserModal';

// Store
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  selectSelectedUser,
  selectShowCreateUserModal,
  selectShowEditUserModal,
  selectTotalUsers,
  selectUsers,
  selectUsersLoading,
} from 'store/users/selectors';
import {
  removeMultipleUsers,
  removeUser,
  searchUsers,
  setShowCreateUserModal,
  setShowEditUserModal,
  getUserMetadata,
  getUserNotifications,
  openFormidiumForUser,
} from 'store/users/actions';
import Portfolio from 'components/pages/Users/Portfolio';

const Users = () => {
  const dispatch = useAppDispatch();

  const showCreateUserModal = useAppSelector(selectShowCreateUserModal);
  const showEditUserModal = useAppSelector(selectShowEditUserModal);
  const users = useAppSelector(selectUsers);
  const totalUsers = useAppSelector(selectTotalUsers);
  const usersLoading = useAppSelector(selectUsersLoading);
  const selectedUser = useAppSelector(selectSelectedUser);

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [search, setSearch] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [userToRemove, setUserToRemove] = useState<User | null>(null);
  const [userToEdit, setUserToEdit] = useState<User | null>(null);
  const [showSelectedUsersRemoveConfirm, setShowSelectedUsersRemoveConfirm] =
    useState<boolean>(false);

  const selectAll = useCallback(() => {
    if (selectedUsers.length > 0) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users);
    }
  }, [selectedUsers, users]);

  const selectUser = useCallback((user: User) => {
    const isSelected = selectedUsers.find((u: User) => u.id === user.id);
    if (isSelected) {
      setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  }, [selectedUsers]);

  const isSelected = useCallback((user: User): boolean => {
    const isSelected = selectedUsers.find((u: User) => u.id === user.id);
    return !!isSelected;
  }, [selectedUsers]);

  const changeSearchValue = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      dispatch(searchUsers({
        offset: page,
        count: rowsPerPage,
        search: e.target.value,
      }));
    }, 1000);
  }, [dispatch, page, rowsPerPage]);

  const onUserRemove = useCallback((user: User | null) => {
    if (user?.id) {
      dispatch(removeUser(user.id));
      setPage(0);
      setRowsPerPage(10);
      setSearch('');
    }
  }, [dispatch]);

  const editClick = useCallback((user: User) => {
    setUserToEdit(user);
    dispatch(getUserMetadata({ user_id: user.id }));
    dispatch(getUserNotifications(user.id));
    dispatch(setShowEditUserModal(true));
  }, [dispatch]);

  const getUsers = useCallback(() => {
    dispatch(searchUsers({
      offset: page,
      count: rowsPerPage,
    }));
  }, [dispatch, page, rowsPerPage]);

  const handleRemoveMultipleUsers = useCallback(() => {
    dispatch(removeMultipleUsers(selectedUsers.map((user) => user.id)));
    setShowSelectedUsersRemoveConfirm(false);
    setSelectedUsers([]);
  }, [dispatch, selectedUsers]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <UsersStyled>
      <UersHead>
        <Stack spacing={2} direction="row" alignItems="flex-end">
          <TextField
            id="users-search"
            label="Search Users"
            variant="standard"
            value={search}
            onChange={changeSearchValue}
          />
          <Button width="160px" onClick={() => dispatch(setShowCreateUserModal(true))}>Create User</Button>
          <Button
            width="135px"
            disabled={selectedUsers.length === 0}
            onClick={() => setShowSelectedUsersRemoveConfirm(true)}
          >
            Delete Selected
          </Button>
        </Stack>
      </UersHead>
      {usersLoading && (
        <UsersLoader>
          <CircularProgress size={50} disableShrink />
        </UsersLoader>
      )}
      {!usersLoading && users.length > 0 && (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={selectedUsers.length > 0 && selectedUsers.length < users.length}
                      checked={selectedUsers.length > 0 && selectedUsers.length === users.length}
                      onChange={selectAll}
                      inputProps={{
                        'aria-label': 'select all users',
                      }}
                    />
                  </TableCell>
                  <TableCell width="100px">ID</TableCell>
                  <TableCell>Account ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell width="50%" align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isSelected(user)}
                        onChange={() => selectUser(user)}
                        inputProps={{
                          'aria-label': 'select all users',
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      ...{user.id.slice(-6)}
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>
                      <Stack
                        spacing={1}
                        direction="row"
                        justifyContent="flex-end"
                      >
                        {/* <Button onClick={() => dispatch(openFormidiumForUser(user.id))}>Formidium</Button> */}
                        <Button onClick={() => editClick(user)}>Edit</Button>
                        <Button width="37px" onClick={() => setUserToRemove(user)}>
                          <DeleteOutlinedIcon />
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={totalUsers}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage: number) => setPage(newPage)}
            onRowsPerPageChange={(e: ChangeEvent<HTMLInputElement>) => setRowsPerPage(+e.target.value)}
          />
        </>
      )}
      <CreateUserModal
        open={showCreateUserModal}
        onClose={() => dispatch(setShowCreateUserModal(false))}
        onSuccess={getUsers}
      />
      <EditUserModal
        open={showEditUserModal}
        user={userToEdit}
      />
      <ConfirmModal
        open={!!userToRemove}
        title={`Are you sure you want to remove ${userToRemove?.name}`}
        onClose={() => setUserToRemove(null)}
        onAccept={() => {
          onUserRemove(userToRemove);
          setUserToRemove(null);
        }}
      />
      <ConfirmModal
        open={showSelectedUsersRemoveConfirm}
        title={`Are you sure you want to remove all selected users?`}
        onClose={() => setShowSelectedUsersRemoveConfirm(false)}
        onAccept={handleRemoveMultipleUsers}
      />
      <Portfolio open={!!selectedUser} />
    </UsersStyled>
  );
}

export default Users;
