import { styled } from '@mui/system';

interface EditUserSudraniaLinkProps {
  disabled?: boolean;
}

export const EditUserModalDataStyled = styled('div')`
  padding: 10px 21px 0 21px;
`;

export const EditUserModalDataPasswordWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 225px;
  align-items: flex-end;
  column-gap: 32px;
  margin-top: 30px;
`;

export const EditUserModalDataSudraniaIdWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 80px;
  align-items: flex-end;
  column-gap: 25px;
  margin-top: 30px;
`;

export const EditUserSudraniaLink = styled('a')<EditUserSudraniaLinkProps>`
  pointer-events: ${(props) => props.disabled ? 'none' : 'initial'};
  text-decoration: none;
`;
