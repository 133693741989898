import type { ChangeEvent, FormEvent } from 'react';
import { useCallback, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import Button from 'components/common/Button';
import { LoginStyled, LoginSubmit } from './Login.styles';
import { login } from 'store/auth/actions';
import { useAppDispatch } from 'hooks';

const Login = () => {
  const dispatch = useAppDispatch();

  const [userId, setUserId] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const onSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    dispatch(
      login({
        userId,
        password,
      }),
    )
  }, [dispatch, userId, password]);
  return (
    <LoginStyled>
      <Card>
        <CardContent>
          <Typography variant="h5" component="div">
            Login
          </Typography>
          <form onSubmit={onSubmit}>
            <div>
              <TextField
                id="user-id"
                label="User ID"
                variant="standard"
                value={userId}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setUserId(e.target.value)}
                required
              />
            </div>
            <div>
              <TextField
                id="password"
                type="password"
                label="Password"
                variant="standard"
                value={password}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                required
              />
            </div>
            <LoginSubmit>
              <Button type="submit">Login</Button>
            </LoginSubmit>
          </form>
        </CardContent>
      </Card>
    </LoginStyled>
  );
};

export default Login;
