import type { LoginParams } from 'api';
import { createAction } from '@reduxjs/toolkit';

// Session
export const updateSessionSecret = createAction<string>('auth/updateSessionSecret');

// User
export const login = createAction<LoginParams>('auth/login');
export const logout = createAction('auth/logout');
export const setAppLoading = createAction<boolean>('auth/setAppLoading');
export const updateUser = createAction<any>('auth/updateUser');
export const getCurrentUser = createAction('auth/getCurrentUser');
