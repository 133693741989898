import type { TableRow } from 'types/Tables';
import { DragDropContext, Droppable, Draggable, type DropResult } from 'react-beautiful-dnd';

import Button from 'components/common/Button';
import {
  TableCreateEditModalBodyActions,
  TableCreateEditModalBodyContainer,
  TableCreateEditModalBodyError,
  TableCreateEditModalBodyTitle,
} from './TableCreateEditModalBody.styles';
import TableCreateEditModalBodyRow from './TableCreateEditModalBodyRow';
import { useCallback } from 'react';

interface TableCreateEditModalBodyProps {
  rows: TableRow[];
  error: string;
  addRow: () => void;
  deleteRow: (index: number) => void;
  updateRow: (index: number, data: TableRow) => void;
  duplicateRow: (data: TableRow) => void;
  reorder: (startIndex: number, endIndex: number) => void;
}

const TableCreateEditModalBody = ({
  rows,
  error,
  addRow,
  deleteRow,
  updateRow,
  duplicateRow,
  reorder,
}: TableCreateEditModalBodyProps) => {
  const onDragEnd = useCallback((result: DropResult) => {
    if (!result.destination) {
      return;
    }
    reorder(
      result.source.index,
      result.destination.index,
    );
  }, [reorder]);

  return (
    <TableCreateEditModalBodyContainer>
      <TableCreateEditModalBodyTitle>
        Rows
      </TableCreateEditModalBodyTitle>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <>
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {rows.map((row, index) => (
                  <Draggable key={row.id} draggableId={row.id as string} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          margin: '5px 0',
                          ...provided.draggableProps.style,
                        }}
                      >
                        <TableCreateEditModalBodyRow
                          {...{
                            index,
                            row,
                            key: row.id,
                            deleteRow: () => deleteRow(index),
                            onChange: (data: TableRow) => updateRow(index, data),
                            duplicateRow: () => duplicateRow(row),
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
              {provided.placeholder}
            </>
          )}
        </Droppable>
      </DragDropContext>
      {error && <TableCreateEditModalBodyError>{error}</TableCreateEditModalBodyError>}
      <TableCreateEditModalBodyActions>
        <Button width="80px" onClick={addRow}>Add Row</Button>
      </TableCreateEditModalBodyActions>
    </TableCreateEditModalBodyContainer>
  );
}

export default TableCreateEditModalBody;
