import type { SudraniaFund, SudraniaFundDetails } from 'types/Sudrania';
import type { ApiError } from 'api';
import { createAction } from '@reduxjs/toolkit';

const PREFIX = 'sudrania';

export type SudraniaError = ApiError | null;

export const setError = createAction<SudraniaError>(`${PREFIX}/setError`);
export const getFunds = createAction(`${PREFIX}/getFunds`);
export const setFunds = createAction<SudraniaFund[]>(`${PREFIX}/setFunds`);
export const getFundDetails = createAction<string>(`${PREFIX}/getFundDetails`);
export const setFundDetails = createAction<SudraniaFundDetails>(`${PREFIX}/setFundDetails`);
export const setFundDetailsError = createAction<string>(`${PREFIX}/setFunDetailsErrors`);
export const setFundDetailsLoading = createAction<boolean>(`${PREFIX}/setFundDetailsLoading`);
export const setFundsLoading = createAction<boolean>(`${PREFIX}/setFundsLoading`);
