import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './rootSaga';

import { authPersistConfig, authReducer } from './auth';
import { corePersistConfig, coreReducer } from './core';
import { usersPersistConfig, usersReducer } from './users';
import { dealsPersistConfig, dealsReducer } from './deals';
import { tablesPersistConfig, tablesReducer } from './tables';
import { sudraniaPersistConfig, sudraniaReducer } from './sudrania';

export const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    core: persistReducer(corePersistConfig, coreReducer),
    auth: persistReducer(authPersistConfig, authReducer),
    users: persistReducer(usersPersistConfig, usersReducer),
    deals: persistReducer(dealsPersistConfig, dealsReducer),
    tables: persistReducer(tablesPersistConfig, tablesReducer),
    sudrania: persistReducer(sudraniaPersistConfig, sudraniaReducer),
  },
  devTools: true,
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
