import type { AxiosResponse } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { ArrayResponseWithTotalInfo } from 'types/common';
import { omit } from 'ramda';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as tablesActions from './actions';

import * as api from 'api';
import { GetTablesRequest, Table } from 'types/Tables';
import { selectActiveSection, selectTablesPage, selectTablesRowsPerPage } from './selectors';
import { store } from 'store';

function* getTables(action: PayloadAction<GetTablesRequest>) {
  try {
    yield put(tablesActions.setLoading(true));
    const res: AxiosResponse<ArrayResponseWithTotalInfo<Table[]>> =
      yield call(api.getTermsAndFinancials, action.payload);
    const tables = res.data.objects;
    yield put(tablesActions.setTotal(res.data.total));
    yield put(tablesActions.setTables(tables));
    yield put(tablesActions.setLoading(false));
  }
  catch (error: any) {
    yield put(tablesActions.setLoading(false));
  }
}

function* addTable(action: PayloadAction<Table>) {
  try {
    const state = store.getState();
    const page = selectTablesPage(state);
    const rowsPerPage = selectTablesRowsPerPage(state);
    yield put(tablesActions.setLoading(true));
    yield call(api.addTermsAndFinancials, action.payload);
    yield put(tablesActions.getTables({
      offset: page,
      total: rowsPerPage,
      type: action.payload.type,
      name_contains: '',
    }));
    yield put(tablesActions.setShowTermCreateModal(false));
    yield put(tablesActions.setShowFinancialCreateModal(false));
    yield put(tablesActions.setLoading(false));
  }
  catch (error: any) {
    yield put(tablesActions.setLoading(false));
  }
}

function* updateTable(action: PayloadAction<Table>) {
  try {
    const state = store.getState();
    const page = selectTablesPage(state);
    const rowsPerPage = selectTablesRowsPerPage(state);
    yield put(tablesActions.setLoading(true));
    yield call(
      api.updateTermsAndFinancials,
      action.payload.id as string,
      omit(['id'], action.payload),
    );
    yield put(tablesActions.getTables({
      offset: page,
      total: rowsPerPage,
      type: action.payload.type,
      name_contains: '',
    }));
    yield put(tablesActions.setShowTermCreateModal(false));
    yield put(tablesActions.setShowFinancialCreateModal(false));
    yield put(tablesActions.setTableToEdit(null));
    yield put(tablesActions.setLoading(false));
  }
  catch (error: any) {
    yield put(tablesActions.setLoading(false));
  }
}

function* deleteTable(action: PayloadAction<string>) {
  try {
    const state = store.getState();
    const page = selectTablesPage(state);
    const rowsPerPage = selectTablesRowsPerPage(state);
    const activeSection = selectActiveSection(state);
    yield put(tablesActions.setLoading(true));
    yield call(api.deleteTermsAndFinancials, action.payload);
    yield put(tablesActions.getTables({
      offset: page,
      total: rowsPerPage,
      type: activeSection,
      name_contains: '',
    }));
    yield put(tablesActions.setLoading(false));
  }
  catch (error: any) {
    yield put(tablesActions.setLoading(false));
  }
}

function* deleteMultipleTables(action: PayloadAction<string[]>) {
  try {
    const state = store.getState();
    const page = selectTablesPage(state);
    const rowsPerPage = selectTablesRowsPerPage(state);
    const activeSection = selectActiveSection(state);
    yield put(tablesActions.setLoading(true));
    yield all(action.payload.map((tableId: string) =>
      call(api.deleteTermsAndFinancials, tableId)
    ))
    yield put(tablesActions.getTables({
      offset: page,
      total: rowsPerPage,
      type: activeSection,
      name_contains: '',
    }));
    yield put(tablesActions.setLoading(false));
  }
  catch (error: any) {
    yield put(tablesActions.setLoading(false));
  }
}

function* tablesSaga() {
  yield all([
    takeLatest(tablesActions.getTables.type, getTables),
    takeLatest(tablesActions.addTable.type, addTable),
    takeLatest(tablesActions.updateTable.type, updateTable),
    takeLatest(tablesActions.deleteTable.type, deleteTable),
    takeLatest(tablesActions.deleteMultipleTables.type, deleteMultipleTables),
  ]);
}

export default tablesSaga;