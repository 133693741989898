import type { ApiError } from 'api';
import type { CreateDealRequest, Deal, GetDealsRequest } from 'types/Deals';
import type { DealType } from './reducer';
import { createAction } from '@reduxjs/toolkit';

const PREFIX = 'deals';

export type DealsError = ApiError | null;

export const setError = createAction<DealsError>(`${PREFIX}/setError`);
export const setShowDealsCreateModal = createAction<boolean>(`${PREFIX}/setShowDealsCreateModal`);
export const getDeals = createAction<GetDealsRequest>(`${PREFIX}/getDeals`);
export const setDealsLoading = createAction<boolean>(`${PREFIX}/setDealsLoading`);
export const setDealsCreateLoading = createAction<boolean>(`${PREFIX}/setDealsCreateLoading`);
export const setDeals = createAction<Deal[]>(`${PREFIX}/setDeals`);
export const setDealsTotal = createAction<number>(`${PREFIX}/setDealsTotal`);
export const setDealToEdit = createAction<Deal | null>(`${PREFIX}/setDealToEdit`);
export const setDealType = createAction<DealType>(`${PREFIX}/setDealType`);
export const createDeal = createAction<CreateDealRequest>(`${PREFIX}/createDeal`);
export const editDeal = createAction<{ id: string, data: Omit<Deal, 'id'> }>(`${PREFIX}/editDeal`);
export const deleteDeal = createAction<string>(`${PREFIX}/deleteDeal`);
export const deleteMultipleDeals = createAction<string[]>(`${PREFIX}/deleteMultipleDeals`);
