import type { UseFormRegister } from 'react-hook-form';
import type { AccountMetadata } from 'types/User';
import { TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  EditUserModalDemographicsPhoneNumber,
  EditUserModalDemographicsCityStateZip,
  EditUserModalDemographicsName,
  EditUserModalDemographicsStyled,
} from './EditUserModalDemographics.styles';

import usStates from '../us_states.json';

interface EditUserModalDemographicsProps {
  register: UseFormRegister<AccountMetadata>;
  state: string;
}

const EditUserModalDemographics = ({ register, state }: EditUserModalDemographicsProps) => (
  <EditUserModalDemographicsStyled role="tabpanel">
    <EditUserModalDemographicsName>
      <TextField
        placeholder="First Name"
        label="First Name"
        variant="standard"
        {...register('first_name')}
      />
      <TextField
        placeholder="Last Name"
        label="Last Name"
        variant="standard"
        {...register('last_name')}
      />
    </EditUserModalDemographicsName>
    <TextField
      placeholder="Address"
      label="Address"
      variant="standard"
      fullWidth
      {...register('address')}
    />
    <EditUserModalDemographicsCityStateZip>
      <TextField
        placeholder="City"
        label="City"
        variant="standard"
        fullWidth
        {...register('city')}
      />
      <FormControl variant="standard">
        <InputLabel id="state">State</InputLabel>
        <Select
          labelId="state"
          label="State"
          value={state}
          {...register('state')}
        >
          {usStates.map((state) => (
            <MenuItem value={state.abbreviation} key={state.abbreviation}>
              {state.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        placeholder="Zip Code"
        label="Zip Code"
        variant="standard"
        fullWidth
        {...register('zip_code')}
      />
    </EditUserModalDemographicsCityStateZip>
    <EditUserModalDemographicsPhoneNumber>
      <TextField
        placeholder="Phone Number"
        label="Phone Number"
        variant="standard"
        fullWidth
        {...register('phone')}
      />
    </EditUserModalDemographicsPhoneNumber>
  </EditUserModalDemographicsStyled>
);

export default EditUserModalDemographics;
