import { Table as Term, TableType } from 'types/Tables';
import { useEffect, useState, useCallback, type ChangeEvent, useRef } from 'react';
import { omit } from 'ramda';

// Hooks
import { useAppDispatch, useAppSelector } from 'hooks';

// Store
import {
  addTable,
  deleteMultipleTables,
  getTables,
  setPage,
  setRowsPerPage,
  setShowTermCreateModal,
  setTableToEdit,
} from 'store/tables/actions';
import { tablesActions } from 'store/tables';
import {
  selectTablesLoading,
  selectTablesTotal,
  selectTablesRowsPerPage,
  selectTables,
  selectTablesPage,
} from 'store/tables/selectors';

// Components and Styles
import { TermsContainer, TermsHead } from './Terms.styles';
import { FinancialsAndTermsLoader } from '../FinancialsAndTerms.styles';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Button from 'components/common/Button';
import TermCreateModal from 'components/pages/TermsAndFinancials/TableCreateEditModal';
import ConfirmModal from 'components/common/ConfirmModal';

const Terms = () => {
  const dispatch = useAppDispatch();

  const terms = useAppSelector(selectTables);
  const loading = useAppSelector(selectTablesLoading);
  const total = useAppSelector(selectTablesTotal);
  const page = useAppSelector(selectTablesPage);
  const rowsPerPage = useAppSelector(selectTablesRowsPerPage);

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [selectedTerms, setSelectedTerms] = useState<Term[]>([]);
  const [termToRemove, setTermToRemove] = useState<Term | null>(null);
  const [showMultipleTermsRemove, setShowMultipleTermsRemove] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('');
  const [search, setSearch] = useState<string>('');

  const selectAll = useCallback(() => {
    if (selectedTerms.length > 0) {
      setSelectedTerms([]);
    } else {
      setSelectedTerms(terms);
    }
  }, [terms, selectedTerms]);

  const selectTerm = useCallback((term: Term) => {
    const isSelected = selectedTerms.find((f: Term) => f.id === term.id);
    if (isSelected) {
      setSelectedTerms(selectedTerms.filter((f) => f.id !== term.id));
    } else {
      setSelectedTerms([...selectedTerms, term]);
    }
  }, [selectedTerms]);

  const isSelected = useCallback((term: Term): boolean => {
    const isSelected = selectedTerms.find((f: Term) => f.id === term.id);
    return !!isSelected;
  }, [selectedTerms]);

  const openTermCreateModal = useCallback(() => {
    dispatch(setShowTermCreateModal(true));
  }, [dispatch]);

  const editTerm = useCallback((term: Term) => {
    dispatch(setTableToEdit(term));
    dispatch(setShowTermCreateModal(true));
  }, [dispatch]);

  const deleteTerm = useCallback(() => {
    if (termToRemove?.id) {
      dispatch(tablesActions.deleteTable(termToRemove.id));
    }
    setTermToRemove(null);
  }, [termToRemove, dispatch]);

  const deleteMultipleTerms = useCallback(() => {
    dispatch(deleteMultipleTables(
      selectedTerms.map((term) => term.id as string)
    ));
    setShowMultipleTermsRemove(false);
    setSelectedTerms([]);
  }, [selectedTerms, dispatch]);

  const duplicateTerm = useCallback((term: Term) => {
    dispatch(addTable(omit(['id'], term)));
  }, [dispatch]);

  const changeSearchValue = useCallback((value: string) => {
    setSearchValue(value);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setSearch(value);
    }, 1000);
  }, []);

  useEffect(() => {
    dispatch(getTables({
      offset: page * rowsPerPage,
      total: rowsPerPage,
      type: TableType.TERM,
      name_contains: search,
    }));
  }, [dispatch, page, rowsPerPage, search]);

  return (
    <TermsContainer>
      <TermsHead>
        <Stack spacing={2} direction="row" alignItems="flex-end">
          <TextField
            label="Search Terms"
            variant="standard"
            value={searchValue}
            onChange={
              (e: ChangeEvent<HTMLInputElement>) =>
                changeSearchValue(e.target.value)
            }
          />
          <Button
            width="185px"
            onClick={openTermCreateModal}
          >
            Create Terms Table
          </Button>
          <Button
            width="135px"
            onClick={() => setShowMultipleTermsRemove(true)}
            disabled={selectedTerms.length === 0}
          >
            Delete Selected
          </Button>
        </Stack>
      </TermsHead>
      {loading && (
        <FinancialsAndTermsLoader>
          <CircularProgress size={50} disableShrink />
        </FinancialsAndTermsLoader>
      )}
      {!loading && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selectedTerms.length > 0 && selectedTerms.length < terms.length}
                    checked={selectedTerms.length > 0 && selectedTerms.length === terms.length}
                    onChange={selectAll}
                    inputProps={{
                      'aria-label': 'select all deals',
                    }}
                  />
                </TableCell>
                <TableCell>ID</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Rows</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {terms.map((term) => (
                <TableRow key={term.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isSelected(term)}
                      onChange={() => selectTerm(term)}
                      inputProps={{
                        'aria-label': 'select deal',
                      }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {term.id}
                  </TableCell>
                  <TableCell align="left">{term.name}</TableCell>
                  <TableCell align="left">
                    {term.table_rows?.length || 0}
                  </TableCell>
                  <TableCell align="right">
                    <Stack
                      spacing={1}
                      direction="row"
                      justifyContent="flex-end"
                    >
                      <Button onClick={() => editTerm(term)}>Edit</Button>
                      <Button width="37px" onClick={() => duplicateTerm(term)}>
                        <FileCopyOutlined />
                      </Button>
                      <Button width="37px" onClick={() => setTermToRemove(term)}>
                        <DeleteOutlinedIcon />
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage: number) => dispatch(setPage(newPage))}
            onRowsPerPageChange={
              (e: ChangeEvent<HTMLInputElement>) =>
                dispatch(setRowsPerPage(+e.target.value))
            }
          />
        </TableContainer>
      )}
      <ConfirmModal
        open={!!termToRemove}
        title={`Are you sure you want to remove ${termToRemove?.name || termToRemove?.id}`}
        onClose={() => setTermToRemove(null)}
        onAccept={deleteTerm}
      />
      <ConfirmModal
        open={showMultipleTermsRemove}
        title={'Are you sure you want to remove all selected terms?'}
        onClose={() => setShowMultipleTermsRemove(false)}
        onAccept={deleteMultipleTerms}
      />
      <TermCreateModal />
    </TermsContainer>
  )
}

export default Terms;
