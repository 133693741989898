import type { ChangeEvent } from 'react';
import type { UseFormRegister } from 'react-hook-form';
import type { AccountMetadata } from 'types/User';
import { useCallback } from 'react';
import { TextField } from '@mui/material';
import Button from 'components/common/Button';
import {
  EditUserModalDataPasswordWrapper,
  EditUserModalDataStyled,
  EditUserModalDataSudraniaIdWrapper,
  EditUserSudraniaLink,
} from './EditUserModalData.styles';
import generatePassword from 'utils/helpers/generatePassword';

interface EditUserModalDataProps {
  email: string;
  password: string;
  passwordConfirm: string;
  sudraniaId: string;
  setEmail: (value: string) => void;
  setPassword: (value: string) => void;
  setPasswordConfirm: (value: string) => void;
  register: UseFormRegister<AccountMetadata>;
}

const EditUserModalData = ({
  email,
  password,
  passwordConfirm,
  sudraniaId,
  setEmail,
  setPassword,
  setPasswordConfirm,
  register,
}: EditUserModalDataProps) => {

  const setGeneratedPassword = useCallback(() => {
    const pass = generatePassword();
    setPassword(pass)
    setPasswordConfirm(pass)
  }, [setPassword, setPasswordConfirm]);

  return (
    <EditUserModalDataStyled role="tabpanel">
      <TextField
        value={email}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
        label="Email"
        placeholder="Email"
        variant="standard"
        fullWidth
      />
      <EditUserModalDataPasswordWrapper>
        <TextField
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
          label="New Password"
          placeholder="New Password"
          variant="standard"
          fullWidth
        />
        <div />
      </EditUserModalDataPasswordWrapper>
      <EditUserModalDataPasswordWrapper>
        <TextField
          value={passwordConfirm}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setPasswordConfirm(e.target.value)}
          label="Confirm New Password"
          placeholder="Confirm New Password"
          variant="standard"
          fullWidth
        />
        <Button onClick={setGeneratedPassword}>
          Reset with Random Password
        </Button>
      </EditUserModalDataPasswordWrapper>
      <EditUserModalDataSudraniaIdWrapper>
        <TextField
          label="Formidium ID"
          placeholder="Formidium ID"
          variant="standard"
          fullWidth
          {...register('sudrania_id')}
        />
        <EditUserSudraniaLink
          href={`https://csduat.formidium.com/formcontrol/form/list/${sudraniaId}`}
          target="__blank"
          disabled={!sudraniaId}
        >
          <Button disabled={!sudraniaId}>Go</Button>
        </EditUserSudraniaLink>
      </EditUserModalDataSudraniaIdWrapper>
    </EditUserModalDataStyled>
  );
}

export default EditUserModalData;
