import { styled } from '@mui/system';

export const TableCreateEditModalFooterContainer = styled('div')`
  padding: 25px 25px 30px 47px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableCreateEditModalFooterButtons = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: 22px;
`;
