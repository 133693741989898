import { styled } from '@mui/system';

export const TableCreateEditModalHeaderContainer = styled('div')`
  padding: 20px 75px 25px 45px;
  font-size: 21px;
`;

export const TableCreateEditModalHeaderTitle = styled('div')`
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 25px;
`;
