import { ChangeEvent, useCallback } from 'react';

// Components and Styles
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from 'components/common/Button';
import ButtonsGroup from 'components/common/ButtonsGroup';
import { DealsHeaderStyled } from './DealsHeader.styles';

// Store
import { useAppDispatch } from 'hooks';
import { setShowDealsCreateModal } from 'store/deals/actions';

const dealTypes = [
  { label: 'All', value: 'all' },
  { label: 'Open', value: 'open' },
  { label: 'Closed', value: 'closed' }
];

interface DealsHeaderProps {
  value: string;
  searchValue: string;
  disableDeleteSelected?: boolean;
  deleteSelectedClick: () => void;
  onChange: (value: string) => void;
  onSearchChange: (value: string) => void;
}

const DealsHeader = ({
  value,
  searchValue,
  disableDeleteSelected,
  deleteSelectedClick,
  onChange,
  onSearchChange,
}: DealsHeaderProps) => {
  const dispatch = useAppDispatch();

  const openCreateDealModal = useCallback(() => {
    dispatch(setShowDealsCreateModal(true));
  }, [dispatch]);

  return (
    <DealsHeaderStyled>
      <Stack spacing={2} direction="row" alignItems="flex-end">
        <TextField
          id="users-search"
          label="Search Deals"
          variant="standard"
          value={searchValue}
          onChange={
            (e: ChangeEvent<HTMLInputElement>) =>
              onSearchChange(e.target.value)
          }
        />
        <Button width="160px" onClick={openCreateDealModal}>
          Create Deal
        </Button>
        <Button
          width="135px"
          onClick={deleteSelectedClick}
          disabled={disableDeleteSelected}
        >
          Delete Selected
        </Button>
      </Stack>
      <ButtonsGroup
        items={dealTypes}
        value={value}
        onChange={onChange}
      />
    </DealsHeaderStyled>
  );
}

export default DealsHeader;
