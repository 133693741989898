import type { AxiosResponse } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { SudraniaFundDetailsRaw, SudraniaResponse } from 'types/Sudrania';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as sudraniaActions from './actions';

import * as api from 'api';
import convertSundraniaFundFromRaw from 'utils/helpers/convertSundraniaFundFromRaw';
import { coreActions } from 'store/core';

function* getFunds() {
  try {
    yield put(sudraniaActions.setFundsLoading(true));
    const res: AxiosResponse<SudraniaResponse<SudraniaFundDetailsRaw[]>> =
      yield call(api.getFundList);
    yield put(sudraniaActions.setFunds(res.data.data.map((fund) =>
      convertSundraniaFundFromRaw(fund))));
    yield put(sudraniaActions.setFundsLoading(false));
  }
  catch (error: any) {
    yield put(sudraniaActions.setFundsLoading(false));
  }
}

function* getFundDetails(action: PayloadAction<string>) {
  try {
    yield put(sudraniaActions.setFundDetailsLoading(true));
    const res: AxiosResponse<SudraniaResponse<SudraniaFundDetailsRaw[]>> = yield call(api.getFundDetails, action.payload);
    if (res.data.data.length > 0) {
      const sudraniaDetailsRaw = res.data.data[0];
      const sudraniaDetails = convertSundraniaFundFromRaw(sudraniaDetailsRaw);
      yield put(sudraniaActions.setFundDetails(sudraniaDetails));
      yield put(sudraniaActions.setFundDetailsError(''));
    } else if(res.data.status === 'Error') {
      yield put(sudraniaActions.setFundDetailsError(res.data.message));
    }
    yield put(sudraniaActions.setFundDetailsLoading(false));
  }
  catch (error: any) {
    yield put(sudraniaActions.setFundDetailsLoading(false));
    yield put(sudraniaActions.setFundDetailsError('Invalid id format'));
    yield put(coreActions.setSnackbarError('Invalid id format'));
  }
}

function* sudraniaSaga() {
  yield all([
    takeLatest(sudraniaActions.getFunds.type, getFunds),
    takeLatest(sudraniaActions.getFundDetails.type, getFundDetails),
  ]);
}

export default sudraniaSaga;