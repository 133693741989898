import type { SyntheticEvent } from 'react';
import type { AccountMetadata, default as User } from 'types/User';
import { useCallback, useState, useEffect } from 'react';
import { reject, equals } from 'ramda';

// Components and Styles
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import {
  EditUserModalActions,
  EditUserModalLoading,
  EditUserModalTitle,
  EditUserModalTitleText,
  TabsDivider,
  TabsWrapper,
} from './EditUserModal.styles';
import Button from 'components/common/Button';
import EditUserModalData from './EditUserModalData';
import EditUserModalDemographics from './EditUserModalDemographics';
import EditUserModalNotifications from './EditUserModalNotifications';

// Store
import { useAppDispatch, useAppSelector } from 'hooks';
import { changeUserPassword, setError, setShowEditUserModal, updateUser, updateUserMetadata, updateUserNotifications } from 'store/users/actions';
import {
  selectEditUserLoading,
  selectUserMetadata,
  selectUserMetadataLoading,
  selectUsersError,
} from 'store/users/selectors';
import { Tab, Tabs } from 'components/common/Tabs';
import { useForm } from 'react-hook-form';

interface EditUserModalProps {
  open: boolean;
  user?: User | null;
}

const EditUserModal = ({ open, user }: EditUserModalProps) => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
  } = useForm<AccountMetadata>();

  const error = useAppSelector(selectUsersError);
  const userMetadata = useAppSelector(selectUserMetadata);
  const editUserLoading = useAppSelector(selectEditUserLoading);
  const userMetadataLoading = useAppSelector(selectUserMetadataLoading);

  const [tabValue, setTabValue] = useState<number>(0);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [emailNewDeal, setEmailNewDeal] = useState<boolean>(false);
  const [emailPaymentClaim, setEmailPaymentClaim] = useState<boolean>(false);
  const [emailDealUpdates, setEmailDealUpdates] = useState<boolean>(false);

  const sudraniaId = watch('sudrania_id');
  const state = watch('state');

  const onClose = useCallback(
    () => {
      dispatch(setShowEditUserModal(false));
      setTabValue(0);
    },
    [dispatch],
  );

  const changeTabValue = useCallback(
    (_: SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
    },
    [],
  );

  const submit = useCallback((data: AccountMetadata) => {
    if (user) {
      if (password && passwordConfirm && password === passwordConfirm) {
        dispatch(changeUserPassword({
          current_password: password,
          new_password: passwordConfirm,
        }));
      }
      if (data) {
        dispatch(updateUserMetadata({
          data: {
            ...reject(equals(''))({
              ...data,
              sudrania_id: data.sudrania_id || '',
            }),
          },
          userId: user.id,
        }));
      }
      dispatch(updateUser({
        userId: user?.id,
        params: {
          email,
          name: user.name,
          level: user.level,
        },
      }));
      dispatch(updateUserNotifications({
        data: {
          email_deal_updates: emailDealUpdates,
          email_new_deal: emailNewDeal,
          email_payment_claim: emailPaymentClaim,
        },
        userId: user.id,
      }));
    }
  }, [
    dispatch,
    user,
    email,
    password,
    passwordConfirm,
    emailDealUpdates,
    emailNewDeal,
    emailPaymentClaim,
  ]);

  useEffect(() => {
    if (userMetadata) {
      setValue('first_name', userMetadata.first_name || '');
      setValue('last_name', userMetadata.last_name || '');
      setValue('address', userMetadata.address || '');
      setValue('city', userMetadata.city || '');
      setValue('state', userMetadata.state || '');
      setValue('sudrania_id', userMetadata.sudrania_id || '');
      setValue('zip_code', userMetadata.zip_code || '');
      setValue('phone', userMetadata.phone || '');
    }
  }, [setValue, userMetadata]);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
    }
  }, [user]);

  useEffect(() => {
    return () => {
      setTabValue(0);
      setEmail('');
      setPassword('');
      setPasswordConfirm('');
      dispatch(setError(null));
    };
  }, [dispatch, open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      {userMetadataLoading && (
        <EditUserModalLoading>
          <CircularProgress size={50} disableShrink />
        </EditUserModalLoading>
      )}
      {!userMetadataLoading && (
        <form onSubmit={handleSubmit(submit)}>
          <EditUserModalTitle>
            <EditUserModalTitleText>User Details</EditUserModalTitleText>
            <FormControlLabel control={<Checkbox />} label="Locked" />
          </EditUserModalTitle>
          <DialogContent>
            {error?.code === 'DUPLICATE' && (
              <Alert severity="error">User with this email or name already exist</Alert>
            )}
            <TabsWrapper>
              <Tabs value={tabValue} onChange={changeTabValue}>
                <Tab label="User" />
                <Tab label="Demographics" />
                <Tab label="Notifications" />
              </Tabs>
              <TabsDivider />
            </TabsWrapper>
            {tabValue === 0 && (
              <EditUserModalData
                email={email}
                password={password}
                passwordConfirm={passwordConfirm}
                sudraniaId={sudraniaId}
                setEmail={setEmail}
                setPassword={setPassword}
                setPasswordConfirm={setPasswordConfirm}
                register={register}
              />
            )}
            {tabValue === 1 && <EditUserModalDemographics register={register} state={state} />}
            {tabValue === 2 && (
              <EditUserModalNotifications
                emailNewDeal={emailNewDeal}
                emailPaymentClaim={emailPaymentClaim}
                emailDealUpdate={emailDealUpdates}
                setEmailNewDeal={setEmailNewDeal}
                setEmailPaymentClaim={setEmailPaymentClaim}
                setEmailDealUpdates={setEmailDealUpdates}
              />
            )}
          </DialogContent>
          <EditUserModalActions>
            <div>
              <Button type="submit" loading={editUserLoading}>OK</Button>
              <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </div>
            {user && <div>id: {user.id}</div>}
          </EditUserModalActions>
        </form>
      )}
    </Dialog>
  );
}

export default EditUserModal;
