import type { ChangeEvent } from 'react';

import {
  DealsCreateModalHeaderStyled,
  DealsCreateModalHeaderTitle,
} from './DealsCreateModalHeader.styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

interface DealsCreateModalHeaderProps {
  hidden: boolean;
  featured: boolean;
  setHidden: (checked: boolean) => void;
  setFeatured: (checked: boolean) => void;
}

const DealsCreateModalHeader = ({
  hidden,
  featured,
  setHidden,
  setFeatured,
}: DealsCreateModalHeaderProps) => (
  <DealsCreateModalHeaderStyled>
    <DealsCreateModalHeaderTitle>Create Deal</DealsCreateModalHeaderTitle>
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={hidden}
            onChange={
              (e: ChangeEvent<HTMLInputElement>) => setHidden(e.target.checked)
            }
          />
        }
        label="Hidden"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={featured}
            onChange={
              (e: ChangeEvent<HTMLInputElement>) => setFeatured(e.target.checked)
            }
          />
        }
        label="Featured"
      />
    </div>
  </DealsCreateModalHeaderStyled>
);

export default DealsCreateModalHeader;
