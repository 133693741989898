import type { AxiosError, AxiosResponse } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';
import type User from 'types/User';
import type { LoginParams, LoginResponse } from 'api';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as api from 'api';

import { showError } from 'utils/helpers/showError';
import * as authActions from './actions';
import type { CoreError } from 'store/core/actions';

function* login(action: PayloadAction<LoginParams>) {
  try {
    const res: AxiosResponse<LoginResponse> = yield call(api.login, action.payload);
    yield put(authActions.updateSessionSecret(res.data.sessionSecret));
    yield put(authActions.updateUser(res.data.session?.user));
  }
  catch (error) {
    showError(error as AxiosError<CoreError>);
  }
}

function* getCurrentUser() {
  try {
    yield put(authActions.setAppLoading(true));
    const res: AxiosResponse<User> = yield call(api.getCurrentUser);
    yield put(authActions.updateUser(res.data));
    yield put(authActions.setAppLoading(false));
  }
  catch(error) {
    yield put(authActions.setAppLoading(false));
  }
}

function* authSaga() {
  yield all([
    takeLatest(authActions.login.type, login),
    takeLatest(authActions.getCurrentUser.type, getCurrentUser),
  ]);
}

export default authSaga;
