import { Route, Routes, Navigate } from 'react-router-dom';

import Login from 'pages/Login';
import Users from 'pages/Users';
import Deals from 'pages/Deals';
import FinancialsAndTerms from 'pages/FinancialsAndTerms';
import ProtectedRoute from './ProtectedRoute';
import NotLoggedRoute from './NotLoggedRoute';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/users" />} />
    <Route path="login" element={
      <NotLoggedRoute>
        <Login />
      </NotLoggedRoute>
    } />
    <Route path="users" element={
      <ProtectedRoute>
        <Users />
      </ProtectedRoute>
    } />
    <Route path="deals" element={
      <ProtectedRoute>
        <Deals />
      </ProtectedRoute>
    } />
    <Route path="financials-and-terms" element={
      <ProtectedRoute>
        <FinancialsAndTerms />
      </ProtectedRoute>
    } />
  </Routes>
);

export default AppRoutes;
