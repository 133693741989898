import type { SyntheticEvent } from 'react';

import Box from '@mui/material/Box';
import Financials from './Financials';
import Terms from './Terms';
import {
  FinancialsAndTermsContainer,
  FinancialsAndTermsTab,
  FinancialsAndTermsTabs,
} from './FinancialsAndTerms.styles';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setActiveSection } from 'store/tables/actions';
import { selectActiveSection } from 'store/tables/selectors';
import { TableType } from 'types/Tables';

const FinancialsAndTerms = () => {
  const dispatch = useAppDispatch();

  const activeSection = useAppSelector(selectActiveSection);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    dispatch(setActiveSection(
      newValue === 0 ? TableType.TERM : TableType.FINANCIAL
    ));
  };

  return (
    <FinancialsAndTermsContainer>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <FinancialsAndTermsTabs
            value={activeSection === TableType.TERM ? 0 : 1}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <FinancialsAndTermsTab label="Terms Tables" />
            <FinancialsAndTermsTab label="Financials Tables" />
          </FinancialsAndTermsTabs>
        </Box>
        <div
          role="tabpanel"
          hidden={activeSection !== TableType.TERM}
          id={`terms-and-financial-tab-0`}
          aria-labelledby={`terms-and-financial-tab-0`}
        >
          {activeSection === TableType.TERM && <Terms />}
        </div>
        <div
          role="tabpanel"
          hidden={activeSection !== TableType.FINANCIAL}
          id={`terms-and-financial-tab-0`}
          aria-labelledby={`terms-and-financial-tab-0`}
        >
          {activeSection === TableType.FINANCIAL && <Financials />}
        </div>
      </Box>
    </FinancialsAndTermsContainer>
  )
}

export default FinancialsAndTerms;
