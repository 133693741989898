import { styled } from '@mui/system';
import { Tab, Tabs } from '@mui/material';

export const TabsStyled = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: #0F9ECE;
  }
`;

export const TabStyled = styled(Tab)`
  font-weight: 500;
  font-size: 14px;
  color: #787878 !important;
  text-transform: capitalize;
`;
