import type { UseFormRegister } from 'react-hook-form';
import type { DealsCreateFields } from '../DealsCreateModal';

// Components and Styles
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { InputLabel } from '@mui/material';
import { DealsCreateModalGeneralSudraniaID } from './DealsCreateModalGeneral.styles';

// Store
import { useAppSelector } from 'hooks';
import { selectFundDetailsError, selectFunds } from 'store/sudrania/selectors';

interface DealsCreateModalGeneralProps {
  sudrania_id: string;
  register: UseFormRegister<DealsCreateFields>;
}


const DealsCreateModalGeneral = ({
  sudrania_id,
  register,
}: DealsCreateModalGeneralProps) => {
  const fundDetailsError = useAppSelector(selectFundDetailsError);
  const funds = useAppSelector(selectFunds);

  return (
    <div>
      <TextField
        label="Title"
        placeholder="Deal Title"
        variant="standard"
        fullWidth
        {...register('name')}
      />
      <DealsCreateModalGeneralSudraniaID>
        <FormControl error={!!fundDetailsError} variant="standard" fullWidth>
          <InputLabel id="demo-simple-select-standard-label">Select Fund</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            label="Select Fund"
            placeholder="Select Fund"
            {...register('sudrania_id')}
            value={sudrania_id || ''}
            error={!!fundDetailsError}
          >
            {funds.map((fund) => (
              <MenuItem key={fund.id} value={fund.id}>{fund.fund_name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DealsCreateModalGeneralSudraniaID>
      <TextField
        label="Minimum Interval (Dollars)"
        placeholder="0.00"
        variant="standard"
        type="number"
        fullWidth
        {...register('minimum_interval')}
      />
    </div>
  );
}

export default DealsCreateModalGeneral;
