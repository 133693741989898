import type { GetTablesRequest, Table, TableType } from 'types/Tables';
import type { ApiError } from 'api';
import { createAction } from '@reduxjs/toolkit';

const PREFIX = 'tables';

export type TablesError = ApiError | null;

export const setError = createAction<TablesError>(`${PREFIX}/setError`);
export const setTables = createAction<Table[]>(`${PREFIX}/setTerms`);
export const setTotal = createAction<number>(`${PREFIX}/setTotal`);
export const setPage = createAction<number>(`${PREFIX}/setPage`);
export const setRowsPerPage = createAction<number>(`${PREFIX}/setRowsPerPage`);
export const setShowTermCreateModal =
  createAction<boolean>(`${PREFIX}/setShowTermCreateModal`);
export const setShowFinancialCreateModal =
  createAction<boolean>(`${PREFIX}/setShowFinancialCreateModal`);
export const setLoading = createAction<boolean>(`${PREFIX}/setLoading`);
export const getTables = createAction<GetTablesRequest>(`${PREFIX}/getTables`);
export const deleteTable = createAction<string>(`${PREFIX}/deleteTable`);
export const deleteMultipleTables = createAction<string[]>(`${PREFIX}/deleteMultipleTables`);
export const addTable = createAction<Table>(`${PREFIX}/addTable`);
export const updateTable = createAction<Table>(`${PREFIX}/updateTable`);
export const setActiveSection = createAction<TableType>(`${PREFIX}/setActiveSection`);
export const setTableToEdit = createAction<Table | null>(`${PREFIX}/setTableToEdit`);
