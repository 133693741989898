// Hooks
import { useAppSelector } from 'hooks';

// Store
import { selectUserPortfolio } from 'store/users/selectors';

// Uitls
import { formatToCurrencyString } from 'utils/helpers';

// Components and Styles
import { StyledTableCell, StyledTableRow } from '../PortfolioTable.styles';
import { InvestmentHistoryTable, InvestmentHistoryWrapper } from './InvestmentHistory.styles';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

const InvestmentHistory = () => {
  const portfolio = useAppSelector(selectUserPortfolio);

  if (!portfolio) return null;

  return (
    <InvestmentHistoryWrapper>
      <InvestmentHistoryTable>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Fund Name</StyledTableCell>
                <StyledTableCell>Invested</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {portfolio.investmentDataList.map((data, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {data.fund_name}
                  </StyledTableCell>
                  <StyledTableCell>{formatToCurrencyString(+data.total_invested)}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </InvestmentHistoryTable>
    </InvestmentHistoryWrapper>
  );
}

export default InvestmentHistory;
