import { styled } from '@mui/system';
import DialogTitle from '@mui/material/DialogTitle';

export const DealsCreateModalHeaderStyled = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px 20px 46px;
`;

export const DealsCreateModalHeaderTitle = styled('div')`
  font-size: 21px;
  font-weight: 700;
  color: black;
`;
