import type { ChangeEvent } from 'react';
import type { SelectChangeEvent } from '@mui/material/Select';
import { TableRowStyle } from 'types/Tables';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { NormalRowContainer } from './TableCreateEditModalBodyRow.styles';
import { options } from './TableCreateEditModalBodyRow';

interface NormalRowProps {
  selectedStyle: TableRowStyle;
  textLeft: string;
  value: string;
  setKey: (value: string) => void;
  setValue: (value: string) => void;
  changeSelectedStyle: (event: SelectChangeEvent) => void;
}

const NormalRow = ({
  textLeft,
  value,
  selectedStyle,
  changeSelectedStyle,
  setKey,
  setValue,
}: NormalRowProps) => {
  return (
    <NormalRowContainer>
      <TextField
        label="Text Left"
        placeholder="Text on left side of table"
        variant="standard"
        value={textLeft}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setKey(e.target.value)}
      />
      <TextField
        label="Text Right"
        placeholder="Corresponding text on right"
        variant="standard"
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
      />
      <FormControl fullWidth>
        <InputLabel style={{ textAlign: 'left' }}>Style</InputLabel>
        <Select
          variant="standard"
          placeholder="Style"
          value={selectedStyle}
          onChange={changeSelectedStyle}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </NormalRowContainer>
  );
}

export default NormalRow;
