import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Tab } from 'components/common/Tabs';

interface DealsCreateModalBodyProps {
  smallPadding?: boolean;
}

export const DealsCreateModalStyled = styled(Dialog)`
  padding: 0;
  & > .MuiDialog-container {
    & > .MuiPaper-root {
      min-width: 700px;
    }
  }
`;

export const DealsCreateModalBody = styled(DialogContent)`
  padding: 0;
`;

export const TabStyled = styled(Tab)`
  min-width: 137px;
`;

export const DealsCreateModalBodyContent = styled(DialogContent)<DealsCreateModalBodyProps>`
  padding: ${(props) => props.smallPadding ? '25px 45px 20px 23px' : '25px 65px 20px 45px'}!important;
  background-color: #F7F7F7;
  overflow-y: auto;
  height: 50vh;
`;

export const DealsCreateModalFooter = styled(DialogActions)`
  padding: 25px 25px 29px 47px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const DealsCreateModalFooterButton = styled('div')`
  display: grid;
  grid-template-columns: 80px 80px;
  column-gap: 22px;
`;
