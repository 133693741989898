import { styled } from '@mui/system';

export const InvestmentHistoryWrapper = styled('div')`
  padding: 30px 46px;
`;

export const InvestmentHistoryTable = styled('div')`
  max-height: 40vh;
  overflow-y: auto;
`;
