import { useEffect, forwardRef, useCallback } from 'react';
import { styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { type AlertProps } from '@mui/material/Alert';

import Header from './components/Header';
import { AppRoutes } from './routes';

// Store
import { useAppDispatch, useAppSelector } from 'hooks';
import { getCurrentUser } from 'store/auth/actions';
import { selectAppLoading } from 'store/auth/selectors';
import { selectSnackbarError } from 'store/core/selectors';
import { clearSnackbarError } from 'store/core/actions';
import { getFunds } from 'store/sudrania/actions';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProgressWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

function App() {
  const dispatch = useAppDispatch();

  const appLoading = useAppSelector(selectAppLoading);
  const snackbarError = useAppSelector(selectSnackbarError);

  const closeSnackbar = useCallback(() => {
    dispatch(clearSnackbarError());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCurrentUser());
    dispatch(getFunds());
  }, [dispatch]);

  if (appLoading) {
    return (
      <ProgressWrapper>
        <CircularProgress size={100} disableShrink />
      </ProgressWrapper>
    );
  };

  return (
    <div>
      <Header />
      <AppRoutes />
      <Snackbar open={!!snackbarError} autoHideDuration={10000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="error" sx={{ width: '100%' }}>
          {snackbarError}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
