import type { SelectChangeEvent } from '@mui/material/Select';
import { useState, useMemo, useCallback, useEffect, useRef } from 'react';

import { type TableRow, TableRowStyle } from 'types/Tables';

// Components and Styles
import {
  TableRowActions,
  TableRowHead,
  TableRowHeadIndex,
  TableRowHeadLeft,
  TableRowHeadOption,
} from './TableCreateEditModalBodyRow.styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import NormalRow from './NormalRow';
import HeadingRow from './HeadingRow';
import Button from 'components/common/Button';

interface TableCreateEditModalBodyRowProps {
  index: number;
  row: TableRow;
  deleteRow: () => void;
  onChange?: (data: TableRow) => void;
  duplicateRow: () => void;
}

export const options = [
  { value: TableRowStyle.HEADING, label: 'Heading' },
  { value: TableRowStyle.NORMAL, label: 'Normal' },
  { value: TableRowStyle.HIGHLIGHT, label: 'Highlight' },
];

const TableCreateEditModalBodyRow = ({
  index,
  row,
  deleteRow,
  onChange,
  duplicateRow,
}:TableCreateEditModalBodyRowProps) => {
  const onChangeTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [selectedStyle, setSelectedStyle] = useState<TableRowStyle>(TableRowStyle.NORMAL);
  const [key, setKey] = useState<string>('');
  const [value, setValue] = useState<string>('');

  const selectedOptionLabel = useMemo((): string => {
    return options.find((option) => option.value === selectedStyle)?.label || '';
  }, [selectedStyle]);

  const updateRow = useCallback((newKey: string, newValue: string, newStyle: TableRowStyle) => {
    if (onChangeTimeoutRef.current) {
      clearTimeout(onChangeTimeoutRef.current);
    }
    onChangeTimeoutRef.current = setTimeout(() => {
      if (onChange) {
        onChange({
          id: row.id,
          key: newKey,
          value: newValue,
          style: newStyle,
        });
      }
    }, 300);
  }, [onChange, row]);

  const onSelectedStyleChange = useCallback((event: SelectChangeEvent) => {
    setSelectedStyle(event.target.value as TableRowStyle);
    updateRow(key, value, event.target.value as TableRowStyle);
  }, [key, value, updateRow]);

  const updateValue = useCallback((newValue: string) => {
    setValue(newValue);
    updateRow(key, newValue, selectedStyle);
  }, [key, selectedStyle, updateRow]);

  const updateKey = useCallback((newKey: string) => {
    setKey(newKey);
    updateRow(newKey, value, selectedStyle);
  }, [value, selectedStyle, updateRow]);

  useEffect(() => {
    if (row) {
      setKey(row.key);
      setValue(row.value);
      setSelectedStyle(row.style);
    }
  }, [row]);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <TableRowHead>
          <TableRowHeadLeft>
            <MenuIcon />
            <TableRowHeadIndex>{index}:</TableRowHeadIndex>
            {selectedStyle === TableRowStyle.HEADING && (
              <span>{value || 'Heading Text String'}</span>
            )}
            {(selectedStyle === TableRowStyle.NORMAL || selectedStyle === TableRowStyle.HIGHLIGHT) && (
              <span>{key || 'Text Left String'}</span>
            )}
          </TableRowHeadLeft>
          <TableRowHeadOption>
            {selectedOptionLabel}
          </TableRowHeadOption>
        </TableRowHead>
      </AccordionSummary>
      <AccordionDetails>
        {(selectedStyle === TableRowStyle.NORMAL || selectedStyle === TableRowStyle.HIGHLIGHT) && (
          <NormalRow
            selectedStyle={selectedStyle}
            textLeft={key}
            value={value}
            setKey={updateKey}
            setValue={updateValue}
            changeSelectedStyle={onSelectedStyleChange}
          />
        )}
        {selectedStyle === TableRowStyle.HEADING && (
          <HeadingRow
            headingText={value}
            selectedStyle={selectedStyle}
            setHeadingText={updateValue}
            changeSelectedStyle={onSelectedStyleChange}
          />
        )}
        <TableRowActions>
          <Button width="37px" onClick={duplicateRow}>
            <FileCopyOutlined/>
          </Button>
          <Button width="37px" onClick={deleteRow}>
            <DeleteOutlinedIcon />
          </Button>
        </TableRowActions>
      </AccordionDetails>
    </Accordion>
  );
}

export default TableCreateEditModalBodyRow;
