import type { RootState } from 'store';

export const selectTermsError = (state: RootState) => state.tables.error;
export const selectTables = (state: RootState) => state.tables.tables;
export const selectShowCreateTermModal = (state: RootState) => state.tables.showCreateTermModal;
export const selectShowFinancialTermModal = (state: RootState) => state.tables.showCreateFinancialModal;
export const selectTablesLoading = (state: RootState) => state.tables.loading;
export const selectActiveSection = (state: RootState) => state.tables.activeSection;
export const selectTableToEdit = (state: RootState) => state.tables.tableToEdit;
export const selectTablesTotal = (state: RootState) => state.tables.total;
export const selectTablesPage = (state: RootState) => state.tables.page;
export const selectTablesRowsPerPage = (state: RootState) => state.tables.rowsPerPage;
