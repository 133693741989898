import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { useAppSelector } from 'hooks';
import { selectUserPortfolio } from 'store/users/selectors';
import { formatToCurrencyString } from 'utils/helpers';
import {
  StyledTableCell,
  StyledTableRow,
} from '../PortfolioTable.styles';
import { PayoutHistoryWrapper, PayoutHistoryTable } from './PayoutHistory.styles';

const PayoutHistory = () => {
  const portfolio = useAppSelector(selectUserPortfolio);

  if (!portfolio) return null;

  return (
    <PayoutHistoryWrapper>
      <PayoutHistoryTable>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Fund Name</StyledTableCell>
                <StyledTableCell>Payout</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {portfolio.investmentDataList.map((data, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {data.fund_name}
                  </StyledTableCell>
                  <StyledTableCell>{formatToCurrencyString(+data.withdrawal)}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PayoutHistoryTable>
    </PayoutHistoryWrapper>
  );
}

export default PayoutHistory;
