import type {
  AccountMetadata,
  ChangeUserPasswordParams,
  default as User,
  GetAccountMetadataParams,
  AccountNotifications,
} from 'types/User';
import type { Portfolio } from 'types/Portfolio';
import type { ApiError, CreateUserParams } from 'api';
import type { SearchUsersParams } from 'api/interfaces/users/searchUsers';
import { createAction } from '@reduxjs/toolkit';

const PREFIX = 'users';

export type UsersError = ApiError | null;

export const setError = createAction<UsersError>(`${PREFIX}/setError`);
export const createUser = createAction<CreateUserParams>(`${PREFIX}/createUser`);
export const setCreateUserLoading = createAction<boolean>(`${PREFIX}/createUserLoading`);
export const setEditUserLoading = createAction<boolean>(`${PREFIX}/editUserLoading`);
export const setShowCreateUserModal = createAction<boolean>(`${PREFIX}/showCreateUserModal`);
export const setShowEditUserModal = createAction<boolean>(`${PREFIX}/setShowEditUserModal`);
export const searchUsers = createAction<SearchUsersParams>(`${PREFIX}/searchUsers`);
export const setUsersLoading = createAction<boolean>(`${PREFIX}/setUsersLoading`);
export const setUsers = createAction<User[]>(`${PREFIX}/setUsers`);
export const setTotalUsers = createAction<number>(`${PREFIX}/setTotalUsers`);
export const removeUser = createAction<string>(`${PREFIX}/removeUser`);
export const removeMultipleUsers = createAction<string[]>(`${PREFIX}/removeMultipleUsers`);
export const updateUser = createAction<{ userId: string, params: CreateUserParams }>(`${PREFIX}/updateUser`);
export const selectUser = createAction<User | null>(`${PREFIX}/selectUser`);
export const changeUserPassword = createAction<ChangeUserPasswordParams>(`${PREFIX}/changeUserPassword`);
export const getUserMetadata = createAction<GetAccountMetadataParams>(`${PREFIX}/getUserMetadata`);
export const setUserMetadata = createAction<AccountMetadata>(`${PREFIX}/setUserMetadata`);
export const setUserMetadataLoading = createAction<boolean>(`${PREFIX}/setUserMetadataLoading`);
export const updateUserMetadata =
  createAction<{ data: Partial<AccountMetadata>, userId: string }>(`${PREFIX}/updateUserMetadata`);
export const getUserNotifications = createAction<string>(`${PREFIX}/getUserNotifications`);
export const setUserNotifications = createAction<AccountNotifications>(`${PREFIX}/setUserNotifications`);
export const updateUserNotifications =
  createAction<{ data: AccountNotifications, userId: string }>(`${PREFIX}/updateUserNotifications`);
export const getPortfolio = createAction<string>(`${PREFIX}/getPortfolio`);
export const setPortfolio = createAction<Portfolio | null>(`${PREFIX}/setPortfolio`);
export const setPortfolioLoading = createAction<boolean>(`${PREFIX}/setPortfolioLoading`);
export const openFormidiumForUser = createAction<string>(`${PREFIX}/openFormidiumForUser`);
