import { styled } from '@mui/system';
import DialogActions from '@mui/material/DialogActions';

export const InputWrapper = styled('div')`
  margin-top: 30px;
  width: 450px;
`;

export const DialogActionsCustom = styled(DialogActions)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 24px;
`;

