import { styled } from '@mui/system';

export const DealDetailsInfoStyled = styled('div')`
  padding: 30px 46px 26px 46px;
  background-color: #f6f6f6;
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 38px;
`;

export const DealDetailsInfoRow = styled('div')`
  font-size: 14px;
  color: black;
  font-family: 'Helvetica', 'Arial', sans-serif;
  line-height: 24px;
  span {
    font-weight: 700;
  }
`;
