import { styled } from '@mui/system';

export const UsersStyled = styled('div')`
  padding: 15px 30px;
`;

export const UersHead = styled('div')`
  margin-bottom: 20px;
`;

export const UsersLoader = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;
