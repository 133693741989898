import { Table as Financial, TableType } from 'types/Tables';
import { useCallback, useEffect, useState, useRef, type ChangeEvent } from 'react';
import { omit } from 'ramda';

// Hooks
import { useAppDispatch, useAppSelector } from 'hooks';

// Store
import {
  addTable,
  deleteMultipleTables,
  setShowFinancialCreateModal,
  setTableToEdit,
  deleteTable,
  getTables,
} from 'store/tables/actions';
import { selectTables, selectTablesLoading, selectTablesTotal } from 'store/tables/selectors';

// Components and Styles
import { FinancialsAndTermsLoader } from '../FinancialsAndTerms.styles';
import { FinancialsContainer, FinancialsHead } from './Financials.styles';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Button from 'components/common/Button';
import FinancialCreateModal from 'components/pages/TermsAndFinancials/TableCreateEditModal';
import ConfirmModal from 'components/common/ConfirmModal';

const Financials = () => {
  const dispatch = useAppDispatch();

  const financials = useAppSelector(selectTables);
  const loading = useAppSelector(selectTablesLoading);
  const total = useAppSelector(selectTablesTotal);

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [selectedFinancials, setSelectedFinancials] = useState<Financial[]>([]);
  const [financialToRemove, setFinancialToRemove] = useState<Financial | null>(null);
  const [showMultipleFinancialsRemove, setShowMultipleFinancialsRemove] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [searchValue, setSearchValue] = useState<string>('');
  const [search, setSearch] = useState<string>('');

  const selectAll = useCallback(() => {
    if (selectedFinancials.length > 0) {
      setSelectedFinancials([]);
    } else {
      setSelectedFinancials(financials);
    }
  }, [financials, selectedFinancials]);

  const selectFinancial = useCallback((financial: Financial) => {
    const isSelected = selectedFinancials.find((f: Financial) => f.id === financial.id);
    if (isSelected) {
      setSelectedFinancials(selectedFinancials.filter((f) => f.id !== financial.id));
    } else {
      setSelectedFinancials([...selectedFinancials, financial]);
    }
  }, [selectedFinancials]);

  const isSelected = useCallback((financial: Financial): boolean => {
    const isSelected = selectedFinancials.find((f: Financial) => f.id === financial.id);
    return !!isSelected;
  }, [selectedFinancials]);

  const openFinancialCreateModal = useCallback(() => {
    dispatch(setShowFinancialCreateModal(true));
  }, [dispatch]);

  const editFinancial = useCallback((term: Financial) => {
    dispatch(setTableToEdit(term));
    dispatch(setShowFinancialCreateModal(true));
  }, [dispatch]);

  const deleteFinancial = useCallback(() => {
    if (financialToRemove?.id) {
      dispatch(deleteTable(financialToRemove?.id));
    }
    setFinancialToRemove(null);
  }, [dispatch, financialToRemove]);

  const deleteMultipleFinancials = useCallback(() => {
    dispatch(deleteMultipleTables(
      selectedFinancials.map((financial) => financial.id as string)
    ));
    setShowMultipleFinancialsRemove(false);
    setSelectedFinancials([]);
  }, [selectedFinancials, dispatch]);

  const duplicateFinancial = useCallback((term: Financial) => {
    dispatch(addTable(omit(['id'], term)));
  }, [dispatch]);

  const changeSearchValue = useCallback((value: string) => {
    setSearchValue(value);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setSearch(value);
    }, 1000);
  }, []);

  useEffect(() => {
    dispatch(getTables({
      offset: page * rowsPerPage,
      total: rowsPerPage,
      type: TableType.FINANCIAL,
      name_contains: search,
    }));
  }, [dispatch, page, rowsPerPage, search]);

  return (
    <FinancialsContainer>
      <FinancialsHead>
        <Stack spacing={2} direction="row" alignItems="flex-end">
          <TextField
            label="Search Financials"
            variant="standard"
            value={searchValue}
            onChange={
              (e: ChangeEvent<HTMLInputElement>) =>
                changeSearchValue(e.target.value)
            }
          />
          <Button
            width="185px"
            onClick={openFinancialCreateModal}
          >
            Create Financials Table
          </Button>
          <Button
            width="135px"
            onClick={() => setShowMultipleFinancialsRemove(true)}
            disabled={selectedFinancials.length === 0}
          >
            Delete Selected
          </Button>
        </Stack>
      </FinancialsHead>
      {loading && (
        <FinancialsAndTermsLoader>
          <CircularProgress size={50} disableShrink />
        </FinancialsAndTermsLoader>
      )}
      {!loading && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selectedFinancials.length > 0 && selectedFinancials.length < financials.length}
                    checked={selectedFinancials.length > 0 && selectedFinancials.length === financials.length}
                    onChange={selectAll}
                    inputProps={{
                      'aria-label': 'select all deals',
                    }}
                  />
                </TableCell>
                <TableCell>ID</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Rows</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {financials.map((financial) => (
                <TableRow key={financial.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isSelected(financial)}
                      onChange={() => selectFinancial(financial)}
                      inputProps={{
                        'aria-label': 'select deal',
                      }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {financial.id}
                  </TableCell>
                  <TableCell align="left">{financial.name}</TableCell>
                  <TableCell align="left">
                    {financial.table_rows?.length || 0}
                  </TableCell>
                  <TableCell align="right">
                    <Stack
                      spacing={1}
                      direction="row"
                      justifyContent="flex-end"
                    >
                      <Button onClick={() => editFinancial(financial)}>Edit</Button>
                      <Button width="37px" onClick={() => duplicateFinancial(financial)}>
                        <FileCopyOutlined />
                      </Button>
                      <Button width="37px" onClick={() => setFinancialToRemove(financial)}>
                        <DeleteOutlinedIcon />
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage: number) => setPage(newPage)}
            onRowsPerPageChange={
              (e: ChangeEvent<HTMLInputElement>) =>
                setRowsPerPage(+e.target.value)
            }
          />
        </TableContainer>
      )}
      <ConfirmModal
        open={!!financialToRemove}
        title={`Are you sure you want to remove ${financialToRemove?.name || financialToRemove?.id}`}
        onClose={() => setFinancialToRemove(null)}
        onAccept={deleteFinancial}
      />
      <ConfirmModal
        open={showMultipleFinancialsRemove}
        title={'Are you sure you want to remove all selected terms?'}
        onClose={() => setShowMultipleFinancialsRemove(false)}
        onAccept={deleteMultipleFinancials}
      />
      <FinancialCreateModal />
    </FinancialsContainer>
  )
}

export default Financials;
