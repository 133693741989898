import { styled } from '@mui/system';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export const FinancialsAndTermsContainer = styled('div')`
  padding: 20px 32px;
`;

export const FinancialsAndTermsTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: #ffce02;
  }
`;

export const FinancialsAndTermsTab = styled(Tab)`
  font-weight: 500;
  font-size: 14px;
  color: #787878 !important;
  text-transform: capitalize;
`;

export const FinancialsAndTermsLoader = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;
