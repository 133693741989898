import { styled } from '@mui/system';
import { Dialog } from '@mui/material';

export const TableCreateEditModalBlock = styled(Dialog)`
  padding: 0;
  font-family: 'Helvetica', 'Arial', sans-serif;
  & > .MuiDialog-container {
    & > .MuiPaper-root {
      min-width: 700px;
    }
  }
`;
