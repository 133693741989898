import type { ChangeEvent, FormEvent } from 'react';
import type { SelectChangeEvent } from '@mui/material/Select';
import type User from 'types/User';
import { useCallback, useState, useEffect } from 'react';

// Enums
import UserLevel from 'types/UserLevel';

// Components
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from 'components/common/Button';
import { DialogActionsCustom, InputWrapper } from './CreateUserModal.styles';

// Store
import { useAppDispatch, useAppSelector } from 'hooks';
import { createUser, setError } from 'store/users/actions';
import { selectCreateUserLoading, selectUsersError } from 'store/users/selectors';

interface CreateUserModalProps {
  open: boolean;
  user?: User | null;
  onClose: () => void;
  onSuccess: () => void;
}

const CreateUserModal = ({ open, onClose, onSuccess }: CreateUserModalProps) => {
  const dispatch = useAppDispatch();

  const createUserLoading = useAppSelector(selectCreateUserLoading);
  const error = useAppSelector(selectUsersError);

  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [userLevel, setUserLevel] = useState<UserLevel>();

  const selectUserLevel = useCallback((event: SelectChangeEvent) => {
    setUserLevel(event.target.value as UserLevel);
  }, []);

  const onSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    const userData = {
      name: username,
      email,
      password,
      level: userLevel || UserLevel.UNPRIVILEGED,
      profiles: [{
        applicationId: 'R2D',
        displayName: username,
      }]
    };
    dispatch(createUser(userData));
    onSuccess();
  }, [
    dispatch,
    onSuccess,
    username,
    email,
    password,
    userLevel,
  ]);

  useEffect(() => {
    return () => {
      setUsername('');
      setEmail('');
      setUserLevel(undefined);
      dispatch(setError(null));
    };
  }, [dispatch, open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={onSubmit}>
        <DialogTitle>New User</DialogTitle>
        <DialogContent>
          {error?.code === 'DUPLICATE' && (
            <Alert severity="error">User with this email or name already exist</Alert>
          )}
          <InputWrapper>
            <TextField
              value={username}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
              label="Username"
              variant="standard"
              required
              fullWidth
            />
          </InputWrapper>
          <InputWrapper>
            <TextField
              value={email}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              type="email"
              label="Email"
              variant="standard"
              required
              fullWidth
            />
          </InputWrapper>
          <InputWrapper>
            <TextField
              value={password}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              type="password"
              label="Password"
              variant="standard"
              required
              fullWidth
            />
          </InputWrapper>
          <InputWrapper>
            <TextField
              value={passwordConfirm}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setPasswordConfirm(e.target.value)}
              type="password"
              label="Password Confirmation"
              variant="standard"
              required
              fullWidth
            />
          </InputWrapper>
          <InputWrapper>
            <FormControl variant="standard" required fullWidth>
              <InputLabel id="user-level-label">User Level</InputLabel>
              <Select
                labelId="user-level-label"
                label="User Level"
                id="user-level"
                value={userLevel}
                onChange={selectUserLevel}
              >
                <MenuItem value={UserLevel.UNPRIVILEGED}>Unprivileged</MenuItem>
                <MenuItem value={UserLevel.USER}>User</MenuItem>
                <MenuItem value={UserLevel.SUPERUSER}>Superuser</MenuItem>
              </Select>
            </FormControl>
          </InputWrapper>
        </DialogContent>
        <DialogActionsCustom>
          <Button type="submit" loading={createUserLoading}>OK</Button>
          <Button variant="outlined" onClick={onClose}>Cancel</Button>
        </DialogActionsCustom>
      </form>
    </Dialog>
  );
}

export default CreateUserModal;
