import { styled } from '@mui/system';
import FormControlLabel from '@mui/material/FormControlLabel';

export const DealsCreateModalGeneralSudraniaID = styled('div')`
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const DealsCreateModalGeneralDate = styled('div')`
  display: grid;
  grid-template-columns: 180px 1fr;
  column-gap: 54px;
  margin-top: 30px;
`;

export const DealsCreateModalGeneralDateTime = styled('div')`
  display: grid;
  grid-template-columns: 60px 60px 60px 1fr;
  column-gap: 14px;
  align-items: flex-end;
`;

export const DealsCreateModalGeneralClosingDate = styled('div')`
  margin-top: 30px;
  display: grid;
  grid-template-columns: 180px 1fr;
  column-gap: 54px;
  align-items: center;
`;

export const ChecbkoxLabel = styled(FormControlLabel)`
  color: black;
  .MuiTypography-root {
    font-size: 14px!important;
  }
`;
