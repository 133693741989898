import Stack from '@mui/material/Stack';
import { useCallback } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';

import {
  HeaderButton,
  HeaderLogo,
  HeaderNav,
  HeaderNavLink,
  HeaderStyled,
} from './Header.styles';

// Store
import { useAppDispatch, useAppSelector } from 'hooks';
import { logout } from 'store/auth/actions';
import { selectSessionUser } from 'store/auth/selectors';

const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isUsersRoute = useMatch('/users');
  const isDealsRoute = useMatch('/deals');
  const isTermsRoute = useMatch('/terms');

  const user = useAppSelector(selectSessionUser);

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <HeaderStyled>
      <HeaderNav>
        <HeaderLogo src="/logo.png" alt="logo" />
        {user && (
          <Stack spacing={3} direction="row">
            <HeaderNavLink to="/users">
              <HeaderButton variant="text" active={!!isUsersRoute}>
                Users
              </HeaderButton>
            </HeaderNavLink>
            <HeaderNavLink to="/deals">
              <HeaderButton variant="text" active={!!isDealsRoute}>
                Deals
              </HeaderButton>
            </HeaderNavLink>
          </Stack>
        )}
      </HeaderNav>
      {user && <Avatar>{user?.name.slice(0, 1)}</Avatar>}
      {user && (
        <HeaderButton variant="text" onClick={handleLogout}>
          Logout
        </HeaderButton>
      )}
      {!user && (
        <HeaderButton variant="text" onClick={() => navigate('/login')}>
          Login
        </HeaderButton>
      )}
    </HeaderStyled>
  );
}

export default Header;
