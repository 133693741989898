import { CircularProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  type SyntheticEvent,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';

// Store
import { getUserMetadata, selectUser } from 'store/users/actions';
import { selectSelectedUser, selectUserMetadata, selectUserMetadataLoading, selectUserPortfolio, selectUserPortfolioLoading } from 'store/users/selectors';

// Components and Styles
import {
  Placeholder,
  PlaceholderText,
  PortfolioBody,
  PortfolioDialog,
  PortfolioHeader,
  PortfolioHeaderClose,
  TabsDivider,
  TabsWrapper,
} from './Portfolio.styles';

import { Tab, Tabs } from 'components/common/Tabs';
import PortfolioOverview from './PortfolioOverview';
import InvestmentHistory from './InvestmentHistory';
import PayoutHistory from './PayoutHistory';

interface PortfolioProps {
  open: boolean;
  onClose?: () => void;
}

const Portfolio = ({ open, onClose }: PortfolioProps) => {
  const dispatch = useAppDispatch();

  const selectedUser = useAppSelector(selectSelectedUser);
  const userMetadata = useAppSelector(selectUserMetadata);
  const userMetadataLoading = useAppSelector(selectUserMetadataLoading);
  const portfolio = useAppSelector(selectUserPortfolio);
  const portfolioLoading = useAppSelector(selectUserPortfolioLoading);

  const [tabValue, setTabValue] = useState<number>(0);

  const loading = useMemo(() => {
    return userMetadataLoading || portfolioLoading
  }, [userMetadataLoading, portfolioLoading]);

  const tabsDisabled = useMemo(() => {
    return loading || !userMetadata?.sudrania_id || !portfolio;
  }, [loading, userMetadata, portfolio]);

  const changeTabValue = useCallback(
    (_: SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
    },
    [],
  );

  const close = useCallback(() => {
    dispatch(selectUser(null));
    if (onClose) {
      onClose();
    }
  }, [dispatch, onClose]);

  useEffect(() => {
    if (open && selectedUser) {
      dispatch(getUserMetadata({ user_id: selectedUser.id }));
    }
  }, [dispatch, open, selectedUser]);

  return (
    <PortfolioDialog
      open={open}
      onClose={close}
      maxWidth="md"
      fullWidth
    >
      <PortfolioHeader>
        <div>User Details</div>
        <PortfolioHeaderClose onClick={close}>
          {'Close [X]'}
        </PortfolioHeaderClose>
      </PortfolioHeader>
      <PortfolioBody>
        <TabsWrapper>
          <Tabs value={tabValue} onChange={changeTabValue}>
            <Tab label="Portfolio Overview" disabled={tabsDisabled} />
            <Tab label="Investment History" disabled={tabsDisabled} />
            <Tab label="Payout History" disabled={tabsDisabled} />
          </Tabs>
          <TabsDivider />
        </TabsWrapper>
        {loading && (
          <Placeholder>
            <CircularProgress size={50} disableShrink />
          </Placeholder>
        )}
        {!loading && !userMetadata?.sudrania_id && (
          <Placeholder>
            <PlaceholderText>
              Please add sudrania id to this user
            </PlaceholderText>
          </Placeholder>
        )}
        {!loading && userMetadata?.sudrania_id && !portfolio && (
          <Placeholder>
            <PlaceholderText>
              Portfolio Not found
            </PlaceholderText>
          </Placeholder>
        )}
        {tabValue === 0 && !tabsDisabled && (
          <PortfolioOverview />
        )}
        {tabValue === 1 && !tabsDisabled && (
          <InvestmentHistory />
        )}
        {tabValue === 2 && !tabsDisabled && (
          <PayoutHistory />
        )}
      </PortfolioBody>
    </PortfolioDialog>
  );
}

export default Portfolio;
