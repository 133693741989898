import type { ChangeEvent } from 'react';

import TextField from '@mui/material/TextField';
import {
  TableCreateEditModalHeaderContainer,
  TableCreateEditModalHeaderTitle,
} from './TableCreateEditModalHeader.styles';
import { TableType } from 'types/Tables';

interface TableCreateEditModalHeaderProps {
  name: string;
  activeSection: TableType;
  editMode: boolean;
  error: string;
  onNameChange: (value: string) => void;
}

const TableCreateEditModalHeader = ({
  name,
  activeSection,
  editMode,
  error,
  onNameChange,
}: TableCreateEditModalHeaderProps) => {
  return (
    <TableCreateEditModalHeaderContainer>
      <TableCreateEditModalHeaderTitle>
        {editMode && (
          <>
            {activeSection === TableType.TERM && 'Edit Term'}
            {activeSection === TableType.FINANCIAL && 'Edit Financial'}
          </>
        )}
        {!editMode && (
          <>
            {activeSection === TableType.TERM && 'Create Term'}
            {activeSection === TableType.FINANCIAL && 'Create Financial'}
          </>
        )}
      </TableCreateEditModalHeaderTitle>
      <TextField
        variant="standard"
        label="Name"
        placeholder="Table Name"
        error={!!error}
        helperText={error}
        value={name}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onNameChange(e.target.value)}
        fullWidth
      />
    </TableCreateEditModalHeaderContainer>
  )
}

export default TableCreateEditModalHeader;
