import { styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';

export const DealsCreateModalImageStyled = styled('div')`
  width: 180px;
  height: 120px;
  border: 1px solid #979797;
  background-color: #D8D8D8;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
`;

export const DealsCreateModalImagePlaceholder = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 0, 0, 0.3);
`;

export const DealsCreateModalImageRemove = styled('button')`
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  color: white;
  position: absolute;
  bottom: 3px;
  right: 3px;
`;

export const Loader = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
`;
